import {
  BasketItemSourceEnum,
  BrokerCodeBonusModel,
  LanguageControlGroup,
  LocationControlModel,
  Nullable,
} from '@xspot-app/common';
import {
  InvalidBasketItemProductDto,
  InvalidBasketItemUpsellDto,
} from '../dtos';

export interface InvalidBasketItemModel {
  basketItemId: string;
  offerId: string;
  offerName: LanguageControlGroup;
  offerVariantId: string;
  offerVariantName: LanguageControlGroup;
  coverMobileUri: string;
  coverDesktopUri: string;
  locations: LocationControlModel[];
  invalidBrokerCodeBonus: BrokerCodeBonusModel;
  brokerCodeLocationsNames: LanguageControlGroup[];
  originalPrice: number;
  actualPrice: number;
  products: InvalidBasketItemProductDto[];
  upsells: InvalidBasketItemUpsellDto[];
  kind: BasketItemSourceEnum;
  voucherId: Nullable<string>;
  voucherValidTo: Nullable<string>;
  voucherCode: string;
}
