import { Nullable } from '@xspot-app/common';

export interface CalendarDaySlotDto {
  isAvailable: boolean;
  date: string;
  from: string;
  to: string;
  originalPrice: Nullable<number>;
  actualPrice: Nullable<number>;
  availableMinutes: Nullable<number>;
  isNightHours: boolean;
  productPromotionIds: string[];
}

export interface CalendarDayVoucherSlotDto
  extends Omit<CalendarDaySlotDto, 'productPromotionIds'> {}
