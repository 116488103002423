import {
  Nullable,
  User,
  UserCreditHistoryListDto,
  UserCreditsDto,
  UserCreditsHeaderDto,
  UserOrdersDto,
  UserReservationDetailsDto,
  UserReservationsDto,
} from '@xspot-app/common';
import { UserProfileModel } from './user-profile.model';
import { GusCompanyDataModel } from './gus-company-data.model';
import { UserOrderDetailsModel } from './user-order-details.model';

export interface UserStateModel {
  user: User | null;
  profile: UserProfileModel | null;
  gusCompanyData: GusCompanyDataModel | null;
  orders: UserOrdersDto;
  orderDetails: Record<string, UserOrderDetailsModel>;
  reservations: UserReservationsDto;
  selectedReservation: Nullable<UserReservationDetailsDto>;
  userCredits: UserCreditsDto;
  userCreditsHeader: Nullable<UserCreditsHeaderDto>;
  creditHistory: UserCreditHistoryListDto;
}
