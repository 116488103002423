import {
  CustomerOfferDetailsVariantModel,
  LocationControlModel,
  Nullable,
} from '@xspot-app/common';

export interface OfferModel {
  id: string;
  activeIndex: number;
  location?: Nullable<LocationControlModel>;
  variant?: Nullable<CustomerOfferDetailsVariantModel>;
  variantProducts?: Record<string, number>;
  upsells?: Record<string, number>;
}
