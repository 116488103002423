import { LanguageControlGroup, LocationsDto } from '../dtos';
import {
  OfferMediaKindEnum,
  ProductKindEnum,
  OfferProductFormEnum,
  OfferProductPriceKindEnum,
  SlotKindEnum,
  VolumePriceDiscountKindEnum,
} from '../enums';
import { Nullable } from '../helpers';

export interface CustomerOfferDetailsModel {
  id: string;
  name: LanguageControlGroup;
  teaser: LanguageControlGroup;
  description: LanguageControlGroup;
  legalContent: LanguageControlGroup;
  locations: LocationsDto;
  media: CustomerOfferDetailsMediaModel[];
  legalFiles: CustomerOfferDetailsFileModel[];
  variants: CustomerOfferDetailsVariantModel[];
  hasUpsells: boolean;
  files: CustomerOfferDetailsFileModel[];
}

export interface CustomerOfferDetailsMediaModel {
  kind: OfferMediaKindEnum;
  uri: string;
  Ordinal: number;
}

export interface CustomerOfferDetailsFileModel {
  uri: string;
  fileName: string;
  ordinal: number;
}

export interface CustomerOfferDetailsVariantModel {
  id: string;
  name: LanguageControlGroup;
  ordinal: number;
  entries: CustomerOfferDetailsVariantEntryModel[];
  products: CustomerOfferDetailsVariantProductModel[];
  information: LanguageControlGroup;
  volumePrices: CustomerOfferDetailsVariantVolumePriceModel[];
}

export interface CustomerOfferDetailsVariantEntryModel {
  header: LanguageControlGroup;
  description: LanguageControlGroup;
  ordinal: number;
}

export interface CustomerOfferDetailsVariantProductModel {
  id: string;
  isMinimalAmount: Nullable<boolean>;
  productKind: ProductKindEnum;
  displayName: LanguageControlGroup;
  productForm: OfferProductFormEnum;
  minAmount?: Nullable<number>;
  maxAmount?: Nullable<number>;
  startAmount?: Nullable<number>;
  amount?: Nullable<number>;
  ordinal: number;
  priceKind: OfferProductPriceKindEnum;
  singlePrice: Nullable<number>;
  segmentPrices: CustomerOfferDetailsVariantProductSegmentPriceModel[];
}

export interface CustomerOfferDetailsVariantProductSegmentPriceModel {
  price: number;
  slotKind: SlotKindEnum;
}

export interface CustomerOfferDetailsVariantVolumePriceModel {
  id: string;
  discountKind: VolumePriceDiscountKindEnum;
  items: CustomerOfferDetailsVariantProductVolumePriceItemModel[];
  products: CustomerOfferDetailsVariantProductVolumePriceProductModel[];
}

export interface CustomerOfferDetailsVariantProductVolumePriceItemModel {
  from: number;
  discountValue: number;
}

export interface CustomerOfferDetailsVariantProductVolumePriceProductModel {
  id: string;
  displayName: LanguageControlGroup;
}

export interface CustomerOfferDetailsVariantProductModelExtension
  extends CustomerOfferDetailsVariantProductModel {
  quantity: number;
}
