import { Injectable } from '@angular/core';
import { StateToken, State, Selector, Action, StateContext } from '@ngxs/store';
import { VoucherService } from '../../services';
import { VoucherStateModel } from './voucher.state-model';
import { Voucher } from './voucher.actions';
import { Observable, tap } from 'rxjs';
import { VoucherStepEnum } from '../../enums';
import { StringUtil, VoucherDto } from '@xspot-app/common';

export const VOUCHER_STATE_TOKEN = new StateToken<VoucherStateModel>('voucher');

const defaultStateValue: VoucherStateModel = {
  currentStep: VoucherStepEnum.AddVoucher,
  voucherEditId: StringUtil.empty,
  vouchers: [],
  selectedLocationId: StringUtil.empty,
};

@State<VoucherStateModel>({
  name: VOUCHER_STATE_TOKEN,
  defaults: defaultStateValue,
})
@Injectable()
export class VoucherState {
  constructor(private voucherService: VoucherService) {}

  @Selector()
  public static vouchers(state: VoucherStateModel): VoucherDto[] {
    return state.vouchers;
  }

  @Selector()
  public static voucherEditId(state: VoucherStateModel): string {
    return state.voucherEditId;
  }

  @Selector()
  public static selectedLocationId(state: VoucherStateModel): string {
    return state.selectedLocationId;
  }

  @Selector()
  public static currentStep(state: VoucherStateModel): VoucherStepEnum {
    return state.currentStep;
  }

  @Action(Voucher.ResetVouchers)
  public ResetVouchers(ctx: StateContext<VoucherStateModel>): void {
    ctx.patchState({
      vouchers: [],
    });
  }

  @Action(Voucher.ResetVoucherState)
  public ResetVoucherState(ctx: StateContext<VoucherStateModel>): void {
    ctx.patchState({ ...defaultStateValue, vouchers: [] });
  }

  @Action(Voucher.SetVoucher)
  public setVoucher(
    ctx: StateContext<VoucherStateModel>,
    action: Voucher.SetVoucher
  ): void {
    ctx.patchState({
      vouchers: [action.voucher],
    });
  }

  @Action(Voucher.RemoveVoucher)
  public removeVoucher(
    ctx: StateContext<VoucherStateModel>,
    { code }: Voucher.RemoveVoucher
  ): void {
    const state = ctx.getState();
    const updatedVouchers = state.vouchers.filter(v => v.code !== code);

    ctx.patchState({
      vouchers: updatedVouchers,
    });
  }

  @Action(Voucher.GetVouchers)
  public getVouchers(
    ctx: StateContext<VoucherStateModel>,
    { payload }: Voucher.GetVouchers
  ): Observable<VoucherDto[]> {
    return this.voucherService.getVoucher(payload).pipe(
      tap(vouchers => {
        const state = ctx.getState();
        const updatedVouchers = [...state.vouchers];

        vouchers.forEach(voucher => {
          const voucherExists = state.vouchers.some(
            v => v.code === voucher.code
          );
          if (!voucherExists) {
            updatedVouchers.push(voucher);
          }
        });

        ctx.patchState({
          vouchers: updatedVouchers,
        });
      })
    );
  }

  @Action(Voucher.UpdateSelectedLocationId)
  public updateActiveLocationId(
    ctx: StateContext<VoucherStateModel>,
    action: Voucher.UpdateSelectedLocationId
  ): void {
    ctx.patchState({ selectedLocationId: action.payload });
  }

  @Action(Voucher.UpdateCurrentStep)
  public updateCurrentStep(
    ctx: StateContext<VoucherStateModel>,
    action: Voucher.UpdateCurrentStep
  ): void {
    ctx.patchState({ currentStep: action.payload });
  }

  @Action(Voucher.SetVoucherEditId)
  public setVoucherEditId(
    ctx: StateContext<VoucherStateModel>,
    action: Voucher.SetVoucherEditId
  ): void {
    ctx.patchState({ voucherEditId: action.payload });
  }
}
