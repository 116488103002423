import {
  CustomerPriceDto,
  LanguageControlGroup,
  OfferCategoryEnum,
  OfferProductKindEnum,
} from '@xspot-app/common';

export interface CustomerOfferDto {
  id: string;
  coverMobileUri: string;
  coverDesktopUri: string;
  name: LanguageControlGroup;
  teaser: LanguageControlGroup;
  ordinal: number;
  price: CustomerPriceDto;
  isMinimumPrice: boolean;
  isPricePerMinute: boolean;
  categories: OfferCategoryEnum[];
  locationIds: string[];
  hasAnyPromotion?: boolean;
  offerProductKind: OfferProductKindEnum;
}
