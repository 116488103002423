import { DateTime, Duration } from 'luxon';
import {
  CalendarDaySlotModel,
  CalendarDaySlotProductPromotionModel,
} from '../../domain';
import {
  CalendarDaySlotDto,
  CalendarDayVoucherSlotDto,
  OfferPromotionDto,
} from '../../dtos';
import { Nullable } from '@xspot-app/common';

export class CalendarDaySlotsModelMapper {
  public static map(
    calendarDaySlotsDto: CalendarDaySlotDto[] | CalendarDayVoucherSlotDto[],
    promotions: Nullable<OfferPromotionDto[]> = null,
    totalQuantity: number = 1
  ): CalendarDaySlotModel[] {
    return calendarDaySlotsDto.map(slot => {
      const date = DateTime.fromFormat(slot.date, 'yyyy-MM-dd');
      const from = Duration.fromISOTime(slot.from);
      const to = Duration.fromISOTime(slot.to);
      const dateTime: DateTime = date.set({
        hour: from.hours,
        minute: from.minutes,
      });

      let productPromotions: CalendarDaySlotProductPromotionModel[] = [];

      if ('productPromotionIds' in slot && promotions) {
        const { productPromotionIds } = slot;

        productPromotions = productPromotionIds.reduce<
          CalendarDaySlotProductPromotionModel[]
        >((acc, promotionId) => {
          const foundPromotion = promotions.find(p => p.id === promotionId);
          if (foundPromotion) {
            foundPromotion.products.forEach(product => {
              acc.push({
                quantity: totalQuantity,
                name: product.displayName,
              });
            });
          }
          return acc;
        }, []);
      }

      return {
        ...slot,
        date,
        dateTime,
        from,
        to,
        productPromotions,
      };
    });
  }
}
