import { UiContext } from '../../enums';
import { LanguageControlGroup } from '../language-control-group.type';

export interface UserReservationDto {
  id: string;
  orderItemNumber: number;
  format: UiContext;
  locationName: LanguageControlGroup;
  offerName: LanguageControlGroup;
  offerVariantName: LanguageControlGroup;
  date: string;
  from: string;
  to: string;
}
