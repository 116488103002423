import {
  Action,
  Selector,
  State,
  StateContext,
  StateToken,
  Store,
} from '@ngxs/store';
import { CalendarsStateModel } from './calendars.state-model';
import { Injectable } from '@angular/core';
import { CalendarTypeEnum } from '../../enums';
import { Nullable, ProductKindEnum } from '@xspot-app/common';
import {
  CalendarDaysDto,
  CalendarDaySlotDto,
  CalendarDaysVoucherDto,
  CalendarDayVoucherSlotDto,
} from '../../dtos';
import { Calendars } from './calendars.actions';
import { CalendarsService } from '../../services/calendar.service';
import { combineLatest, Observable, of, switchMap, timer } from 'rxjs';
import { produce } from 'immer';
import {
  CalendarDaySlotModel,
  CalendarDaysModel,
  SelectedSlotModel,
} from '../../domain';
import { CalendarStepEnum } from '../../enums/calendar-step.enum';
import {
  CalendarDaySlotsModelMapper,
  CalendarDaysModelMapper,
} from '../../mappers';
import { DateTime } from 'luxon';
import { OffersState } from '../offers';

export const CALENDARS_STATE_TOKEN = new StateToken<CalendarsStateModel>(
  'calendars'
);

const defaultStateValue: CalendarsStateModel = {
  currentStep: CalendarStepEnum.Days,
  type: null,
  days: null,
  daySlots: null,
  selectedDate: null,
  currentYearMonth: DateTime.now().startOf('month'),
  selectedSlots: [],
  minSlotMinutes: 0,
};

@State<CalendarsStateModel>({
  name: CALENDARS_STATE_TOKEN,
  defaults: defaultStateValue,
})
@Injectable()
export class CalendarsState {
  constructor(
    private calendarsService: CalendarsService,
    private store: Store
  ) {}

  @Selector()
  public static currentStep(state: CalendarsStateModel): CalendarStepEnum {
    return state.currentStep;
  }

  @Selector()
  public static type(state: CalendarsStateModel): Nullable<CalendarTypeEnum> {
    return state.type;
  }

  @Selector()
  public static days(state: CalendarsStateModel): Nullable<CalendarDaysModel> {
    return state.days;
  }

  @Selector()
  public static daySlots(
    state: CalendarsStateModel
  ): Nullable<CalendarDaySlotModel[]> {
    return state.daySlots;
  }

  @Selector()
  public static selectedDate(state: CalendarsStateModel): Nullable<DateTime> {
    return state.selectedDate;
  }

  @Selector()
  public static currentYearMonth(state: CalendarsStateModel): DateTime {
    return state.currentYearMonth;
  }

  @Selector()
  public static selectedSlots(state: CalendarsStateModel): SelectedSlotModel[] {
    return state.selectedSlots;
  }

  @Selector()
  public static minSlotMinutes(state: CalendarsStateModel): number {
    return state.minSlotMinutes;
  }

  @Action(Calendars.UpdateCurrentStep, {
    cancelUncompleted: true,
  })
  public updateCurrentStep(
    ctx: StateContext<CalendarsStateModel>,
    action: Calendars.UpdateCurrentStep
  ): void {
    ctx.patchState({ currentStep: action.payload });
  }

  @Action(Calendars.UpdateType, {
    cancelUncompleted: true,
  })
  public updateType(
    ctx: StateContext<CalendarsStateModel>,
    action: Calendars.UpdateType
  ): void {
    ctx.patchState({ type: action.payload });
  }

  @Action(Calendars.UpdateSelectedDate, {
    cancelUncompleted: true,
  })
  public updateSelectedDate(
    ctx: StateContext<CalendarsStateModel>,
    action: Calendars.UpdateSelectedDate
  ): void {
    ctx.patchState({ selectedDate: action.payload });
  }

  @Action(Calendars.UpdateCurrentYearMonth, {
    cancelUncompleted: true,
  })
  public updateCurrentYearMonth(
    ctx: StateContext<CalendarsStateModel>,
    action: Calendars.UpdateCurrentYearMonth
  ): void {
    ctx.patchState({ currentYearMonth: action.payload.startOf('month') });
  }

  @Action(Calendars.UpdateSelectedSlots, {
    cancelUncompleted: true,
  })
  public updateSelectedSlots(
    ctx: StateContext<CalendarsStateModel>,
    action: Calendars.UpdateSelectedSlots
  ): void {
    ctx.patchState({ selectedSlots: action.payload });
  }

  @Action(Calendars.LoadDays, {
    cancelUncompleted: true,
  })
  public loadDays(
    ctx: StateContext<CalendarsStateModel>,
    { dto }: Calendars.LoadDays
  ): Observable<CalendarDaysDto> {
    return combineLatest([
      this.calendarsService.getCalendarDays(dto),
      timer(100),
    ]).pipe(
      switchMap(([response]) => {
        const promotions = this.store.selectSnapshot(OffersState.promotions);

        const state = produce(ctx.getState(), draft => {
          draft.days = CalendarDaysModelMapper.map(
            response,
            ctx.getState().currentYearMonth,
            promotions
          );
          draft.minSlotMinutes = response.minutesAmount ?? 0;
        });

        ctx.setState(state);
        return of(response);
      })
    );
  }

  @Action(Calendars.LoadVoucherDays, {
    cancelUncompleted: true,
  })
  public loadVoucherDays(
    ctx: StateContext<CalendarsStateModel>,
    { dto }: Calendars.LoadVoucherDays
  ): Observable<CalendarDaysVoucherDto> {
    return combineLatest([
      this.calendarsService.getVoucherCalendarDays(dto),
      timer(100),
    ]).pipe(
      switchMap(([response]) => {
        const state = produce(ctx.getState(), draft => {
          draft.days = CalendarDaysModelMapper.map(
            response,
            ctx.getState().currentYearMonth
          );
          draft.minSlotMinutes = response.minutesAmount ?? 0;
        });

        ctx.setState(state);
        return of(response);
      })
    );
  }

  @Action(Calendars.LoadDaySlots, {
    cancelUncompleted: true,
  })
  public loadDaySlots(
    ctx: StateContext<CalendarsStateModel>,
    { dto }: Calendars.LoadDaySlots
  ): Observable<CalendarDaySlotDto[]> {
    return combineLatest([
      this.calendarsService.getCalendarDaySlots(dto),
      timer(100),
    ]).pipe(
      switchMap(([response]) => {
        const promotions = this.store.selectSnapshot(OffersState.promotions);
        const selectedVariantProducts = this.store.selectSnapshot(
          OffersState.selectedVariantProducts
        );
        const totalQuantity = selectedVariantProducts.reduce(
          (acc, { productKind, quantity = 0 }) =>
            productKind === ProductKindEnum.Reservation ? acc + quantity : acc,
          0
        );

        const state = produce(ctx.getState(), draft => {
          draft.daySlots = CalendarDaySlotsModelMapper.map(
            response,
            promotions,
            totalQuantity
          );
        });
        ctx.setState(state);
        return of(response);
      })
    );
  }

  @Action(Calendars.LoadVoucherDaySlots, {
    cancelUncompleted: true,
  })
  public loadVoucherDaySlots(
    ctx: StateContext<CalendarsStateModel>,
    { dto }: Calendars.LoadVoucherDaySlots
  ): Observable<CalendarDayVoucherSlotDto[]> {
    return combineLatest([
      this.calendarsService.getVoucherCalendarDaySlots(dto),
      timer(100),
    ]).pipe(
      switchMap(([response]) => {
        const state = produce(ctx.getState(), draft => {
          draft.daySlots = CalendarDaySlotsModelMapper.map(response);
        });
        ctx.setState(state);
        return of(response);
      })
    );
  }

  @Action(Calendars.ResetDaySlots)
  public resetDaySlots(ctx: StateContext<CalendarsStateModel>): void {
    ctx.patchState({ daySlots: null });
  }

  @Action(Calendars.ResetCalendarState)
  public resetCalendarState(ctx: StateContext<CalendarsStateModel>): void {
    ctx.patchState({ ...defaultStateValue, selectedSlots: [] });
  }
}
