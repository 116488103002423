import { UiContext } from '../enums';
import { LanguageControlGroup } from './language-control-group.type';

export interface LocationDto {
  id: string;
  name: LanguageControlGroup;
  format: UiContext;
  address: string;
  latitude: number;
  longitude: number;
}
