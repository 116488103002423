import { Nullable } from '@xspot-app/common';

export interface GetCalendarDaysDto {
  offerId: string;
  offerVariantId: string;
  locationId: string;
  startDate: string;
  endDate: string;
  basketItemId: Nullable<string>;
  offerVariantProducts: GetCalendarDaysOfferVariantProductDto[];
}

export interface GetCalendarDaysOfferVariantProductDto {
  id: string;
  quantity: number;
}
