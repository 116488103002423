import {
  BasketItemSourceEnum,
  BasketOfferProductDto,
  CreditKindEnum,
  LanguageControlGroup,
  Nullable,
} from '@xspot-app/common';

export interface InvalidBasketDto {
  unavailable: InvalidBasketItemDto[];
  priceChanged: InvalidBasketItemDto[];
}

export interface InvalidBasketItemDto {
  basketItemId: string;
  offerId: string;
  offerName: LanguageControlGroup;
  offerVariantId: string;
  offerVariantName: LanguageControlGroup;
  coverMobileUri: string;
  coverDesktopUri: string;
  locationIds: string[];
  invalidBrokerCodeBonus: BrokerCodeBonusDto;
  originalPrice: number;
  actualPrice: number;
  products: InvalidBasketItemProductDto[];
  upsells: InvalidBasketItemUpsellDto[];
  kind: BasketItemSourceEnum;
  voucherId: Nullable<string>;
  voucherValidTo: Nullable<string>;
  voucherCode: string;
}

export interface BrokerCodeBonusDto {
  bonusMinutes: number;
  locationIds: string[];
  creditKind: CreditKindEnum;
}

export interface InvalidBasketItemUpsellDto {
  basketItemUpsellId: string;
  upsellName: LanguageControlGroup;
  quantity: number;
  originalPrice: number;
  actualPrice: number;
}

export interface InvalidBasketItemProductDto extends BasketOfferProductDto {}
