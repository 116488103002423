import { CreditPaymentInfoDto, LanguageControlGroup } from '../dtos';
import { BasketItemSourceEnum, CodesKind, CreditKindEnum } from '../enums';
import { Nullable } from '../helpers';
import { LocationControlModel } from './location-control.model';

export interface BasketModel {
  totalPrice: number;
  originalPrice: number;
  appliedDiscountCode: string;
  appliedDiscountCodeType: CodesKind;
  offers: BasketOfferModel[];
  creditPaymentInfo: Nullable<CreditPaymentInfoDto>;
}

export interface BasketOfferModel {
  id: string;
  offerId: string;
  offerVariantId: string;
  totalPrice: number;
  originalPrice: number;
  name: LanguageControlGroup;
  variantName: LanguageControlGroup;
  locations: LocationControlModel[];
  brokerCodeLocationsNames: LanguageControlGroup[];
  nonReservationProducts: BasketOfferProductModel[];
  reservationProducts: BasketOfferReservationProductModel[];
  allProducts: BasketOfferProductModel[];
  upsells: BasketOfferUpsellModel[];
  brokerCodeBonus: BrokerCodeBonusModel;
  detailsVisibility: boolean;
  coverDesktopUri: string;
  coverMobileUri: string;
  kind: BasketItemSourceEnum;
  voucherId: Nullable<string>;
  voucherValidTo: Nullable<string>;
  voucherCode: string;
  isCreditOffer: boolean;
}

export interface BasketOfferInvalidModel
  extends Omit<BasketOfferModel, 'brokerCodeBonus'> {
  invalidBrokerCodeBonus: BrokerCodeBonusModel;
}

export interface BrokerCodeBonusModel {
  bonusMinutes: number;
  locationIds: string[];
  creditKind: CreditKindEnum;
}

export interface BasketOfferProductModel {
  offerVariantProductId: string;
  totalPrice: number;
  actualPrice: number;
  originalPrice: number;
  name: LanguageControlGroup;
  quantity: number;
  promotionProducts?: BasketPromotionProductModel[];
}

export interface BasketPromotionProductModel {
  id: string;
  displayName: LanguageControlGroup;
}

export interface BasketOfferUpsellModel {
  upsellId: string;
  totalPrice: number;
  name: LanguageControlGroup;
  quantity: number;
}

export interface BasketOfferReservationProductModel {
  date: string;
  slots: BasketOfferSlotModel[];
  isReservationPro: boolean;
}

export interface BasketOfferSlotModel {
  timeFrom: string;
  timeTo: string;
  products: BasketOfferProductModel[];
  minutes: Nullable<number>;
}
