import { FlyspotB2BStatusEnum, Language } from '../../enums';
import { UserAgreementDto } from './user-agreement.dto';

export interface UserProfileDto {
  email: string;
  name: string;
  surname: string;
  canUseDelayedPayment: boolean;
  dateOfBirth: string | null;
  language: Language;
  emailConfirmed: boolean | null;
  phoneNumberPrefix: string;
  phoneNumberWithoutPrefix: string;
  agreements: UserAgreementDto[];
  flyspotB2B: FlyspotB2BStatusEnum;
  deepspotB2B: string; // string of comma separated DeepspotB2BStatusEnum
}
