import { DateTime } from 'luxon';

export function tomorrow(): DateTime {
  return today().plus({ day: 1 });
}

export function today(): DateTime {
  return DateTime.local().startOf('day');
}

export function twoYears(): DateTime {
  return today().plus({ months: 24 });
}

export function isSameMonthAndYear(date1: DateTime, date2: DateTime): boolean {
  return date1.year === date2.year && date1.month === date2.month;
}

export function isSameDay(date1: DateTime, date2: DateTime): boolean {
  return isSameMonthAndYear(date1, date2) && date1.day === date2.day;
}

export function isSameDateTime(date1: DateTime, date2: DateTime): boolean {
  return (
    isSameDay(date1, date2) &&
    date1.hour === date2.hour &&
    date1.minute === date2.minute &&
    date1.second === date2.second
  );
}

export function isPreviousDate(date: DateTime, compareTo: DateTime): boolean {
  return date.startOf('day') < compareTo.startOf('day');
}
