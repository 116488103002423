import { FormControl } from '@angular/forms';
import {
  CustomerOfferDetailsVariantModel,
  LocationControlModel,
  Nullable,
} from '@xspot-app/common';

export interface OfferFormModel {
  location: FormControl<Nullable<LocationControlModel>>;
  variant: FormControl<Nullable<CustomerOfferDetailsVariantModel>>;
  variantProducts: FormControl<Record<string, number>>;
  upsells: FormControl<Record<string, number>>;
  selectedDate: FormControl<Nullable<Date>>;
}
