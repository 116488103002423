import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService, BasketDto, environment } from '@xspot-app/common';
import {
  CreateBasketVoucherItemDto,
  DiscountCodeDto,
  UpsertBasketItemDto,
} from '../dtos';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BasketsService {
  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {}

  public addItem(payload: UpsertBasketItemDto): Observable<void> {
    return this.http.post<void>(`${environment.apiUrl}/api/baskets/items`, {
      ...payload,
    });
  }

  public addVoucherItem(payload: CreateBasketVoucherItemDto): Observable<void> {
    return this.http.post<void>(
      `${environment.apiUrl}/api/baskets/VoucherItems`,
      {
        ...payload,
      }
    );
  }

  public updateItem(payload: UpsertBasketItemDto): Observable<void> {
    return this.http.put<void>(
      `${environment.apiUrl}/api/baskets/items/${payload.basketId}`,
      payload
    );
  }

  public addDiscountCode(payload: DiscountCodeDto): Observable<void> {
    return this.http.put<void>(
      `${environment.apiUrl}/api/baskets/code`,
      payload
    );
  }

  public getItems(): Observable<BasketDto> {
    return this.http.get<BasketDto>(
      `${environment.apiUrl}/api/baskets?includeCreditPaymentsInfo=${this.authService.isLoggedIn()}`
    );
  }

  public deleteItem(id: string): Observable<void> {
    return this.http.delete<void>(
      `${environment.apiUrl}/api/baskets/items/${id}`
    );
  }
}
