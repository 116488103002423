import { CreditKindEnum } from '../../enums';
import { LanguageControlGroup } from '../language-control-group.type';

export interface UserCreditDto {
  id: string;
  amount: number;
  amountLeft: number;
  expiresAt: string;
  kind: CreditKindEnum;
  createdAt: string;
  locationNames: LanguageControlGroup[];
}
