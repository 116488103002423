<div *transloco="let t">
  <ng-container *ngIf="dialogConfig.data?.orderDetails as order">
    <div
      class="flex justify-content-center p-4"
      [ngClass]="{
        'bg-flyspot-primary': order.format === UiContext.Flyspot,
        'bg-deepspot-primary': order.format === UiContext.Deepspot,
      }">
      <img [src]="'assets/' + order.format.toLowerCase() + '/logo_white.svg'" />
    </div>

    <div class="flex flex-column p-5 gap-4">
      <div class="flex flex-column">
        <span class="text-oswald text-2xl uppercase"> {{ order.format }}</span>
        <p-divider styleClass="mt-0 mb-2"></p-divider>
        <div class="flex flex-wrap row-gap-2">
          <div class="w-full lg:w-6">
            <span class="mr-2 font-bold">
              {{ t('userProfile.orderModal.orderNumber') }}
            </span>
            <span>{{ order.number }}</span>
          </div>

          <div class="w-full lg:w-6">
            <span class="mr-2 font-bold">
              {{ t('userProfile.orderModal.paymentMethod') }}
            </span>
            <span>
              {{ t(order.paymentMethodKey) }}
            </span>
          </div>

          <div class="w-full lg:w-6">
            <span class="mr-2 font-bold">
              {{ t('userProfile.orderModal.orderDate') }}
            </span>
            <span>{{ order.createdAt | date: 'dd.MM.yyyy HH.mm' }}</span>
          </div>
        </div>
      </div>

      <div class="flex justify-content-between surface-200 p-3 font-semibold">
        <span>
          {{ t('userProfile.orderModal.orderStatus') }}
        </span>
        <span
          [ngClass]="{
            'text-success': order.status === PaymentStatus.Paid,
            'text-warning':
              order.status === PaymentStatus.Pending ||
              order.status === PaymentStatus.PartiallyPaid,
            'text-error': order.status === PaymentStatus.Expired,
          }">
          {{ t('userProfile.userOrders.paymentStatus.' + order.status) }}
        </span>
      </div>

      <div class="text-oswald text-3xl font-semibold uppercase">
        {{ t('userProfile.orderModal.orderDetails') }}
      </div>

      <div
        *ngIf="lang$ | async as lang"
        class="flex flex-column gap-5 custom-overflow pr-3">
        <div *ngFor="let item of order.items">
          <div class="flex flex-column gap-2">
            <span class="text-oswald text-2xl uppercase">
              {{ item.offerName[lang] }}
            </span>
            <div class="flex gap-2">
              <span>{{ t('userProfile.orderModal.reservationNumber') }}:</span>
              <span class="font-semibold">{{ item.number }}</span>
            </div>
            <span>
              {{ item.offerVariantName[lang] }},
              {{ parseLocationNames(item.locationNames) }}
            </span>
          </div>

          <div>
            @if (item.isMinimalAmount) {
              <div
                *ngFor="let slot of item.groupedReservationProducts | keyvalue"
                class="flex flex-column gap-2 py-2">
                <span class="font-semibold">{{ parseDate(slot.key) }}</span>

                <div class="flex flex-column gap-2">
                  <div
                    *ngFor="let reservationProduct of slot.value"
                    class="flex justify-content-between">
                    <span class="text-color-secondary">
                      {{ reservationProduct.from }} -
                      {{ reservationProduct.to }},
                      {{ reservationProduct.numberOfMinutes }}
                      {{ t('userProfile.orderModal.min') }}
                    </span>
                    <span class="font-semibold">
                      {{
                        reservationProduct.price | currencyFormatPipe | async
                      }}
                    </span>
                  </div>
                </div>
              </div>
            } @else {
              <div
                *ngFor="let slot of item.groupedReservationProducts | keyvalue"
                class="flex flex-column gap-2 py-2">
                <span class="font-semibold">
                  {{ parseDate(slot.key) }},
                  {{ slot.value[0].from?.substring(0, 5) }} -
                  {{ slot.value[0].to?.substring(0, 5) }}
                </span>

                <div class="flex flex-column gap-2">
                  <div
                    *ngFor="let reservationProduct of slot.value"
                    class="flex justify-content-between">
                    <span class="text-color-secondary">
                      {{ reservationProduct.productDisplayName[lang] }} x{{
                        reservationProduct.quantity
                      }}
                    </span>
                    <span class="font-semibold">
                      {{
                        reservationProduct.price | currencyFormatPipe | async
                      }}
                    </span>
                  </div>
                </div>
              </div>
            }

            <div
              *ngFor="let nonReservationProduct of item.nonReservationProducts"
              class="flex justify-content-between">
              <span class="text-color-secondary">
                {{ nonReservationProduct.productDisplayName[lang] }} x{{
                  nonReservationProduct.quantity
                }}
              </span>
              <span class="font-semibold">
                {{ nonReservationProduct.price | currencyFormatPipe | async }}
              </span>
            </div>

            <p-divider styleClass="mb-2"></p-divider>

            <div class="font-semibold flex justify-content-between">
              <span>{{ t('userProfile.orderModal.sum') }}</span>
              <span>{{ item.price | currencyFormatPipe | async }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <div class="flex justify-content-end p-5 pt-4">
    <p-button
      severity="secondary"
      [outlined]="true"
      styleClass="px-6"
      [label]="t('userProfile.orderModal.close')"
      (onClick)="close()">
    </p-button>
  </div>
</div>
