import { PaymentStatus, UiContext } from '../../enums';
import { LanguageControlGroup } from '../language-control-group.type';

export interface UserReservationDetailsDto {
  id: string;
  orderId: string;
  number: number;
  orderNumber: number;
  orderStatus: PaymentStatus;
  format: UiContext;
  locationName: LanguageControlGroup;
  offerName: LanguageControlGroup;
  offerVariantName: LanguageControlGroup;
  voucherCode: string;
  date: string;
  from: string;
  to: string;
}
