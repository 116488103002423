import { Nullable } from '@xspot-app/common';
import { DateTime, Duration } from 'luxon';
import { CalendarDaySlotProductPromotionModel } from './calendar-day-slot.model';

export interface SelectedSlotModel {
  date: DateTime;
  dateTime: DateTime;
  endTime: Duration;
  minutes: Nullable<number>;
  originalPrice: number;
  actualPrice: number;
  productPromotions: CalendarDaySlotProductPromotionModel[];
}
