import { VoucherDto } from '@xspot-app/common';
import { VoucherStepEnum } from '../../enums';

export namespace Voucher {
  export class GetVouchers {
    public static readonly type = '[Voucher] Get Vouchers';
    constructor(public payload: string[]) {}
  }

  export class ResetVouchers {
    public static readonly type = '[Voucher] Reset Vouchers';
  }

  export class ResetVoucherState {
    public static readonly type = '[Voucher] Reset Voucher State';
  }

  export class RemoveVoucher {
    public static readonly type = '[Voucher] Remove Voucher';
    constructor(public code: string) {}
  }

  export class UpdateSelectedLocationId {
    public static readonly type = '[Voucher] Update Selected Location Id';
    constructor(public payload: string) {}
  }

  export class UpdateCurrentStep {
    public static readonly type = '[Voucher] Update Current Step';
    constructor(public payload: VoucherStepEnum) {}
  }

  export class SetVoucher {
    public static readonly type = '[Voucher] Set Voucher';
    constructor(public voucher: VoucherDto) {}
  }

  export class SetVoucherEditId {
    public static readonly type = '[Voucher] Set Voucher Edit Id';
    constructor(public payload: string) {}
  }
}
