import {
  CurrentPriceModel,
  CustomerOfferDetailsModel,
  CustomerOfferModel,
  CustomerUpsellModel,
  Nullable,
  OfferCategoryEnum,
  SelectedUpsellModel,
  VariantProductModel,
} from '@xspot-app/common';
import { ShoppingPathStepEnum } from '../../enums';
import { OfferPromotionModel } from '../../domain';

export interface OffersStateModel {
  currentStep: ShoppingPathStepEnum;
  offers: CustomerOfferModel[];
  activeLocationId: string;
  activeCategory: OfferCategoryEnum;
  offer: Nullable<CustomerOfferDetailsModel>;
  selectedVariantId: string;
  selectedVariantProducts: VariantProductModel[];
  currentPrice: CurrentPriceModel;
  basketItemId: Nullable<string>;
  upsells: CustomerUpsellModel[];
  selectedUpsells: SelectedUpsellModel[];
  promotions: OfferPromotionModel[];
}
