import {
  CreateUserDto,
  CreateExternalUserDto,
  UpdateUserProfileDto,
  UpdateUserAgreementsDto,
  GetUserOrdersDto,
  GetUserReservationsDto,
  GetUserReservationDetailsDto,
  GetUserCreditsDto,
  GetUserCreditHistoryDto,
} from '@xspot-app/common';

export namespace User {
  export class Create {
    public static readonly type = '[Users] Create';
    constructor(public payload: CreateUserDto) {}
  }

  export class CreateExternal {
    public static readonly type = '[Users] Create External';
    constructor(public payload: CreateExternalUserDto) {}
  }

  export class FetchProfile {
    public static readonly type = '[Users] Fetch Profile';
  }

  export class UpdateProfile {
    public static readonly type = '[Users] Update Profile';
    constructor(public payload: Partial<UpdateUserProfileDto>) {}
  }

  export class UpdateAgreements {
    public static readonly type = '[Users] Update Agreements';
    constructor(public payload: UpdateUserAgreementsDto) {}
  }

  export class GetGusCompanyData {
    public static readonly type = '[Users] Get Gus company data';
    constructor(public taxNumber: string) {}
  }

  export class GetUserOrders {
    public static readonly type = '[Users] Get User Orders';
    constructor(public payload: GetUserOrdersDto) {}
  }

  export class GetUserOrderDetails {
    public static readonly type = '[Users] Get User Order Details';
    constructor(public orderId: string) {}
  }

  export class GetUserReservations {
    public static readonly type = '[Users] Get User Reservations';
    constructor(public payload: GetUserReservationsDto) {}
  }

  export class GetUserReservationDetails {
    public static readonly type = '[Users] Get User Reservation Details';
    constructor(public payload: GetUserReservationDetailsDto) {}
  }

  export class GetUserCredits {
    public static readonly type = '[Users] Get User Credits';
    constructor(public payload: GetUserCreditsDto) {}
  }

  export class GetUserCreditsHeader {
    public static readonly type = '[Users] Get User Credits Header';
  }

  export class GetUserCreditHistory {
    public static readonly type = '[Users] Get User Credit History';
    constructor(
      public creditId: string,
      public params: GetUserCreditHistoryDto
    ) {}
  }
}
