import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  CreateExternalUserDto,
  CreateUserDto,
  GetUserCreditHistoryDto,
  GetUserCreditsDto,
  GetUserOrdersDto,
  GetUserReservationDetailsDto,
  GetUserReservationsDto,
  GusCompanyDataDto,
  UpdateUserAgreementsDto,
  UpdateUserProfileDto,
  UserCreditHistoryListDto,
  UserCreditsDto,
  UserCreditsHeaderDto,
  UserOrderDetailsDto,
  UserOrdersDto,
  UserProfileDto,
  UserReservationDetailsDto,
  UserReservationsDto,
} from '../dtos';
import { environment } from '../environments/environment';
import { Language } from '../enums';
import { pagedListQueryParams } from '../helpers';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient) {}

  public create(data: CreateUserDto): Observable<void> {
    return this.http.post<void>(`${environment.apiUrl}/api/users`, {
      ...data,
    });
  }

  public fetchProfile(): Observable<UserProfileDto> {
    return this.http.get<UserProfileDto>(
      `${environment.apiUrl}/api/users/profile`
    );
  }

  public updateProfile(data: Partial<UpdateUserProfileDto>): Observable<void> {
    return this.http.put<void>(`${environment.apiUrl}/api/users/profile`, {
      ...data,
    });
  }

  public updateLanguage(language: Language): Observable<void> {
    return this.http.put<void>(`${environment.apiUrl}/api/users/language`, {
      language,
    });
  }

  public createExternalUser(data: CreateExternalUserDto): Observable<void> {
    return this.http.post<void>(`${environment.apiUrl}/api/users/external`, {
      ...data,
    });
  }

  public updateAgreements(data: UpdateUserAgreementsDto): Observable<void> {
    return this.http.put<void>(`${environment.apiUrl}/api/users/agreements`, {
      ...data,
    });
  }

  public getGusCompanyData(taxNumber: string): Observable<GusCompanyDataDto> {
    return this.http.get<GusCompanyDataDto>(
      `${environment.apiUrl}/api/users/getGusCompanyData/${taxNumber}`
    );
  }

  public getUserOrders(params: GetUserOrdersDto): Observable<UserOrdersDto> {
    const queryParams = pagedListQueryParams(params);

    return this.http.get<UserOrdersDto>(
      `${environment.apiUrl}/api/users/orders`,
      {
        params: queryParams,
      }
    );
  }

  public getUserOrderDetails(orderId: string): Observable<UserOrderDetailsDto> {
    return this.http.get<UserOrderDetailsDto>(
      `${environment.apiUrl}/api/users/orders/${orderId}`
    );
  }

  public getReservation(
    params: GetUserReservationDetailsDto
  ): Observable<UserReservationDetailsDto> {
    const queryParams: HttpParams = new HttpParams()
      .set('date', params.date)
      .set('from', params.from)
      .set('to', params.to);

    return this.http.get<UserReservationDetailsDto>(
      `${environment.apiUrl}/api/users/OrderItemsGrouped/${params.id}`,
      { params: queryParams }
    );
  }

  public getReservations(
    params: GetUserReservationsDto
  ): Observable<UserReservationsDto> {
    const queryParams: HttpParams = new HttpParams()
      .set('pageSize', params.pageSize)
      .set('pageNumber', params.pageNumber)
      .set('mode', params.mode);

    return this.http.get<UserReservationsDto>(
      `${environment.apiUrl}/api/users/OrderItemsGrouped`,
      { params: queryParams }
    );
  }

  public getUserCredits(params: GetUserCreditsDto): Observable<UserCreditsDto> {
    const queryParams = pagedListQueryParams(params).set('mode', params.mode);

    return this.http.get<UserCreditsDto>(
      `${environment.apiUrl}/api/userCredits`,
      {
        params: queryParams,
      }
    );
  }

  public getUserCreditsHeader(): Observable<UserCreditsHeaderDto> {
    return this.http.get<UserCreditsHeaderDto>(
      `${environment.apiUrl}/api/userCredits/header`
    );
  }

  public getUserCreditHistory(
    creditId: string,
    params: GetUserCreditHistoryDto
  ): Observable<UserCreditHistoryListDto> {
    const queryParams = pagedListQueryParams(params);

    return this.http.get<UserCreditHistoryListDto>(
      `${environment.apiUrl}/api/userCredits/${creditId}/paymentHistory`,
      {
        params: queryParams,
      }
    );
  }
}
