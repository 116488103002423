import { produce } from 'immer';
import {
  BrokerCodeBonusModel,
  CustomerLocationsListDto,
  LanguageControlGroup,
} from '@xspot-app/common';
import { InvalidBasketItemDto } from '../dtos';
import { InvalidBasketItemModel } from '../domain';

export function mapLocations<
  T extends {
    brokerCodeBonus?: BrokerCodeBonusModel;
    brokerCodeLocationsNames: LanguageControlGroup[];
    locations: {
      id: string;
      name: LanguageControlGroup;
    }[];
  },
>(products: T[], locations: CustomerLocationsListDto[]): T[] {
  return produce(products, draft => {
    draft.forEach(product => {
      product.brokerCodeLocationsNames =
        product?.brokerCodeBonus?.locationIds?.map(locationId =>
          getLocationNameById(locationId, locations)
        ) || [];

      product.locations = product.locations.map(location => ({
        id: location.id,
        name: getLocationNameById(location.id, locations),
      }));
    });
  });
}

export function getLocationNameById(
  id: string,
  allLocations: CustomerLocationsListDto[]
): LanguageControlGroup {
  const loc = allLocations.find(l => l.id === id);
  return loc ? loc.name : ({} as LanguageControlGroup);
}

export function mapLocationsInvalid(
  basketItems: InvalidBasketItemDto[],
  locations: CustomerLocationsListDto[]
): InvalidBasketItemModel[] {
  return basketItems.map(item => {
    const mappedLocations = item.locationIds.map(locationId => {
      const loc = locations.find(l => l.id === locationId);

      return {
        id: locationId,
        name: loc ? loc.name : ({} as LanguageControlGroup),
      };
    });

    const brokerCodeLocationsNames =
      item?.invalidBrokerCodeBonus?.locationIds?.map(locationId =>
        getLocationNameById(locationId, locations)
      ) || [];

    return { ...item, locations: mappedLocations, brokerCodeLocationsNames };
  });
}
