import {
  Component,
  Input,
  ChangeDetectionStrategy,
  ViewChild,
  ChangeDetectorRef,
  OnInit,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslocoDirective } from '@ngneat/transloco';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { ProgressBarModule } from 'primeng/progressbar';
import { OverlayPanel, OverlayPanelModule } from 'primeng/overlaypanel';
import { InputNumberModule } from 'primeng/inputnumber';
import { DividerModule } from 'primeng/divider';
import {
  IsItemSelectedDirective,
  isSameDateTime,
  Nullable,
  TimeFormatPipe,
  XTooltipDirective,
} from '@xspot-app/common';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { CalendarDaySlotModel, SelectedSlotModel } from '../../../../domain';
import { CalendarTypeEnum } from '../../../../enums';
import { Calendars, CalendarsState, Offers } from '../../../../state';
import { NgLetModule } from 'ng-let';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { hasActionsExecuting } from '@ngxs-labs/actions-executing';
import { SlotPriceComponent } from '../../shared/slot-price/slot-price.component';
import { OfferPromotionComponent } from '../../../offer-promotion/offer-promotion.component';
import { DateTime } from 'luxon';

@UntilDestroy()
@Component({
  selector: 'xspot-app-time-slots',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    IsItemSelectedDirective,
    TranslocoDirective,
    DividerModule,
    OfferPromotionComponent,
    ProgressBarModule,
    ButtonModule,
    OverlayPanelModule,
    InputNumberModule,
    XTooltipDirective,
    SlotPriceComponent,
    TimeFormatPipe,
    NgLetModule,
  ],
  templateUrl: './time-slots.component.html',
  styleUrls: ['./time-slots.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeSlotsComponent implements OnInit {
  @Input({ required: true }) public slotsData: CalendarDaySlotModel[] = [];
  @Input() public filterDate: DateTime = DateTime.now();

  @ViewChild('calendarFlyspotProOverlay')
  private calendarFlyspotProOverlay!: OverlayPanel;
  protected calendarFlyspotProOverlayInputValue: number = 0;
  protected calendarFlyspotProOverlaySelectedSlot: Nullable<CalendarDaySlotModel> =
    null;
  protected maxQuantity: number = 30;
  protected CalendarTypeEnum = CalendarTypeEnum;

  @Select(
    hasActionsExecuting([Calendars.LoadDaySlots, Calendars.LoadVoucherDaySlots])
  )
  protected isLoading$!: Observable<boolean>;

  @Select(CalendarsState.type)
  public calendarType$!: Observable<CalendarTypeEnum>;
  @Select(CalendarsState.selectedSlots)
  public selectedSlots$!: Observable<SelectedSlotModel[]>;
  @Select(CalendarsState.minSlotMinutes)
  public minSlotMinutes$!: Observable<number>;

  constructor(
    private store: Store,
    private cdr: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this.store
      .select(CalendarsState.selectedSlots)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.cdr.detectChanges();
      });
  }

  protected selectSlot(slot: CalendarDaySlotModel, event: MouseEvent): void {
    const calendarType = this.store.selectSnapshot(CalendarsState.type);
    if (!slot.isAvailable) {
      return;
    }

    const clickedElement = event.currentTarget as HTMLElement;

    if (calendarType === CalendarTypeEnum.FlyspotPro) {
      const currentSelectedSlot = this.store
        .selectSnapshot(CalendarsState.selectedSlots)
        .find(x => isSameDateTime(x.dateTime, slot.dateTime));
      const minSlotMinutes = this.store.selectSnapshot(
        CalendarsState.minSlotMinutes
      );
      this.calendarFlyspotProOverlayInputValue =
        currentSelectedSlot?.minutes ?? minSlotMinutes;
      this.calendarFlyspotProOverlaySelectedSlot = slot;

      clickedElement.classList.add('border-primary');

      this.calendarFlyspotProOverlay.toggle(event);
      this.calendarFlyspotProOverlay.onHide.subscribe(() => {
        clickedElement.classList.remove('border-primary');
      });

      return;
    }
    this.addToSelectedSlots(slot);
  }

  protected addToSelectedSlots(
    slot: CalendarDaySlotModel,
    minutesAmount: Nullable<number> = null
  ): void {
    const calendarType = this.store.selectSnapshot(CalendarsState.type);
    const priceMultiplier: number =
      calendarType === CalendarTypeEnum.FlyspotPro ? minutesAmount! : 1;
    const selectedSlot: SelectedSlotModel = {
      date: slot.date,
      dateTime: slot.date.set({
        hour: slot.from.hours,
        minute: slot.from.minutes,
      }),
      endTime: slot.to,
      minutes: minutesAmount,
      originalPrice: (slot.originalPrice ?? 0) * priceMultiplier,
      actualPrice: (slot.actualPrice ?? 0) * priceMultiplier,
      productPromotions: slot.productPromotions,
    };

    const currentSelectedSlots =
      calendarType === CalendarTypeEnum.Flyspot
        ? []
        : this.store
            .selectSnapshot(CalendarsState.selectedSlots)
            .filter(x => !isSameDateTime(x.dateTime, slot.dateTime));
    const newSelectedSlots = [...currentSelectedSlots, selectedSlot];
    this.store.dispatch(new Calendars.UpdateSelectedSlots(newSelectedSlots));
    this.store.dispatch(new Offers.ResetSelectedUpsells());
  }

  protected getSelectedSlotModel(
    slot: CalendarDaySlotModel
  ): Nullable<SelectedSlotModel> {
    const selectedSlots = this.store.selectSnapshot(
      CalendarsState.selectedSlots
    );
    return selectedSlots?.find(x => x.dateTime.equals(slot.dateTime)) ?? null;
  }

  protected asCalendarDaySlotModel(model: unknown): CalendarDaySlotModel {
    return model as CalendarDaySlotModel;
  }
}
