import { LanguageControlGroup } from '../dtos';

export interface CustomerUpsellModel {
  id: string;
  price: number;
  coverMobileUri: string;
  coverDesktopUri: string;
  name: LanguageControlGroup;
  description: LanguageControlGroup;
  highlight: LanguageControlGroup;
  teaser: LanguageControlGroup;
  maxQuantity: number;
}
