import { Injectable } from '@angular/core';
import { combineLatest, map, take } from 'rxjs';
import { AuthService } from './auth.service';
import { jwtToUser, Nullable } from '../helpers';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslocoService } from '@ngneat/transloco';
import { UiContextService } from './ui-context.service';
import {
  BasketModel,
  BasketOfferModel,
  CurrentPriceModel,
  CustomerOfferDetailsModel,
  CustomerOfferModel,
  CustomerUpsellModel,
  SelectedUpsellModel,
  VariantProductModel,
} from '../models';
import { BasketItemSourceEnum, PaymentMethodEnum } from '../enums';
import { CustomerLocationsListDto, VoucherDto } from '../dtos';

export interface CustomWindow extends Window {
  dataLayer: Array<Record<string, unknown>>;
}

declare let window: CustomWindow;

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class DataLayerService {
  private userData$ = combineLatest([
    this.authService.isAuthenticated$,
    this.authService.accessToken$,
  ]).pipe(
    untilDestroyed(this),
    map(([isAuthenticated, accessToken]) => {
      let userId = undefined;

      if (isAuthenticated) {
        const user = jwtToUser(accessToken) as { userId?: string };

        userId = user.userId;
      }

      return {
        user_id: userId,
        client_type: undefined,
        client_segment_fly: undefined,
        client_segment_deep: undefined,
      };
    })
  );

  private lang$ = this.translocoService.langChanges$;

  constructor(
    private authService: AuthService,
    private translocoService: TranslocoService,
    private uiContextService: UiContextService
  ) {}

  private pushEvent(event: string, data: Record<string, unknown>): void {
    combineLatest([this.userData$, this.lang$])
      .pipe(take(1))
      .subscribe(([userData, lang]) => {
        window.dataLayer.push({ event, ...userData, ...data, language: lang });
      });
  }

  public pushReservationStartEvent(): void {
    this.pushEvent('reservation_start', {});
  }

  public pushOfferListEvent(data: {
    selectedCity: string;
    selectedProductSegment: string;
    offers: CustomerOfferModel[];
    currency: string;
    locations: CustomerLocationsListDto[];
  }): void {
    window.dataLayer.push({ ecommerce: null });

    this.uiContextService.currentContext$.pipe(take(1)).subscribe(context => {
      this.pushEvent('view_item_list', {
        reservation_path: context,
        voucher_path: 'no',
        selected_product_segment: data.selectedProductSegment,
        item_list_id: context,
        item_list_name: context,
        selected_city: data.selectedCity,
        eccommerce: {
          items: data.offers.map((offer, index) => ({
            item_id: offer.id,
            item_name: offer.name['pl'],
            affiliation: offer.locationIds.map(
              locationId =>
                data.locations.find(loc => loc.id === locationId)?.name?.['pl']
            ),
            currency: data.currency,
            price: offer.price.actual,
            discount: offer.price.original - offer.price.actual,
            index: index,
            item_brand: context,
            item_category: data.selectedProductSegment,
            item_category2: data.selectedCity,
            item_category3: undefined,
            item_category4: undefined,
            item_category5: undefined,
            item_variant: undefined,
            age_group_option: undefined,
            upsell_product: 'no',
            quantity: 1,
            full_price: offer.price.original,
          })),
        },
      });
    });
  }

  public pushOfferDetailsEvent(data: {
    selectedCity: string;
    selectedProductSegment: string;
    currency: string;
    offer: CustomerOfferDetailsModel;
    currentPrice: CurrentPriceModel;
  }): void {
    window.dataLayer.push({ ecommerce: null });

    this.uiContextService.currentContext$.pipe(take(1)).subscribe(context => {
      this.pushEvent('view_item', {
        reservation_path: context,
        voucher_path: 'no',
        selected_product_segment: data.selectedProductSegment,
        selected_city: data.selectedCity,
        eccommerce: {
          value: data.currentPrice.actual,
          currency: data.currency,
          items: [
            {
              item_id: data.offer.id,
              item_name: data.offer.name['pl'],
              affiliation: data.offer.locations.map(
                location => location.name['pl']
              ),
              currency: data.currency,
              price: data.currentPrice.actual,
              discount: data.currentPrice.original - data.currentPrice.actual,
              index: 0,
              item_brand: context,
              item_category: data.selectedProductSegment,
              item_category2: data.selectedCity,
              item_category3: undefined,
              item_category4: undefined,
              item_category5: undefined,
              item_variant: undefined,
              age_group_option: undefined,
              upsell_product: 'no',
              quantity: 1,
              full_price: data.currentPrice.original,
            },
          ],
        },
      });
    });
  }

  public pushOfferCalendarEvent(data: {
    selectedCity: string;
    selectedProductSegment: string;
    currency: string;
    offer: CustomerOfferDetailsModel;
    selectedVariantProducts: VariantProductModel[];
    currentPrice: CurrentPriceModel;
    selectedVariantId: string;
  }): void {
    window.dataLayer.push({ ecommerce: null });

    this.uiContextService.currentContext$.pipe(take(1)).subscribe(context => {
      this.pushEvent('reservation_date_selection', {
        reservation_path: context,
        voucher_path: 'no',
        selected_product_segment: data.selectedProductSegment,
        selected_city: data.selectedCity,
        eccommerce: {
          value: data.currentPrice.actual,
          currency: data.currency,
          items: this.mapProductsToDataLayerItems({ ...data, context }),
        },
      });
    });
  }

  public pushAddBasketItemEvent(data: {
    selectedCity: string;
    selectedProductSegment: string;
    currency: string;
    offer: CustomerOfferDetailsModel;
    selectedVariantProducts: VariantProductModel[];
    currentPrice: CurrentPriceModel;
    selectedVariantId: string;
    selectedUpsells: SelectedUpsellModel[];
    upsells: CustomerUpsellModel[];
  }): void {
    window.dataLayer.push({ ecommerce: null });

    this.uiContextService.currentContext$.pipe(take(1)).subscribe(context => {
      this.pushEvent('add_to_cart', {
        reservation_path: context,
        voucher_path: 'no',
        selected_product_segment: data.selectedProductSegment,
        selected_city: data.selectedCity,
        eccommerce: {
          value: data.currentPrice.actual,
          currency: data.currency,
          items: this.mapProductsToDataLayerItems({
            ...data,
            context,
          }).concat(
            this.mapSelectedUpsellsToDataLayerItems({ ...data, context })
          ),
        },
      });
    });
  }

  public pushUpsellListEvent(data: {
    selectedCity: string;
    selectedProductSegment: string;
    currency: string;
    offer: CustomerOfferDetailsModel;
    upsells: CustomerUpsellModel[];
  }): void {
    window.dataLayer.push({ ecommerce: null });

    this.uiContextService.currentContext$.pipe(take(1)).subscribe(context => {
      this.pushEvent('view_item_list', {
        reservation_path: context,
        voucher_path: 'no',
        selected_product_segment: data.selectedProductSegment,
        item_list_id: context,
        item_list_name: context,
        selected_city: data.selectedCity,
        eccommerce: {
          items: data.upsells.map((upsell, index) => ({
            item_id: upsell.id,
            item_name: upsell.name['pl'],
            affiliation: data.selectedCity,
            currency: data.currency,
            price: upsell.price,
            discount: 0,
            index: index,
            item_brand: context,
            item_category: data.selectedProductSegment,
            item_category2: data.selectedCity,
            item_category3: undefined,
            item_category4: undefined,
            item_category5: undefined,
            item_variant: undefined,
            age_group_option: undefined,
            upsell_product: 'yes',
            quantity: 1,
            full_price: upsell.price,
          })),
        },
      });
    });
  }

  public pushRemoveFromBasketEvent(data: {
    selectedCity: string;
    selectedProductSegment: string;
    currency: string;
    offer: BasketOfferModel;
  }): void {
    window.dataLayer.push({ ecommerce: null });

    this.uiContextService.currentContext$.pipe(take(1)).subscribe(context => {
      this.pushEvent('remove_from_cart', {
        reservation_path: context,
        voucher_path:
          data.offer.kind === BasketItemSourceEnum.Voucher ? 'yes' : 'no',
        selected_product_segment: data.selectedProductSegment,
        selected_city: data.selectedCity,
        eccommerce: {
          value: data.offer.totalPrice,
          currency: data.currency,
          items: this.mapBasketOfferToDataLayerItems({
            ...data,
            context,
            index: 0,
          }),
        },
      });
    });
  }

  public pushDisplayBasketEvent(
    event: 'view_cart' | 'begin_checkout',
    data: {
      selectedCity: string;
      selectedProductSegment: string;
      currency: string;
      basket: BasketModel;
    }
  ): void {
    window.dataLayer.push({ ecommerce: null });

    this.uiContextService.currentContext$.pipe(take(1)).subscribe(context => {
      this.pushEvent(event, {
        reservation_path: context,
        voucher_path: this.isVoucherPath(data.basket),
        selected_product_segment: data.selectedProductSegment,
        selected_city: data.selectedCity,
        eccommerce: {
          value: data.basket.totalPrice,
          currency: data.currency,
          items: data.basket.offers.flatMap((offer, index) =>
            this.mapBasketOfferToDataLayerItems({
              ...data,
              offer,
              context,
              index,
            })
          ),
        },
      });
    });
  }

  public pushLoginRegistrationStartEvent(
    event:
      | 'login_start'
      | 'registration_start'
      | 'registration_step_2'
      | 'registration_step_3',
    data: {
      selectedCity: string;
      selectedProductSegment: string;
      currency: string;
      basket: Nullable<BasketModel>;
    }
  ): void {
    if (data.basket) {
      window.dataLayer.push({ ecommerce: null });

      this.uiContextService.currentContext$.pipe(take(1)).subscribe(context => {
        if (!data.basket) {
          return;
        }

        this.pushEvent(event, {
          reservation_path: context,
          voucher_path: this.isVoucherPath(data.basket),
          selected_product_segment: data.selectedProductSegment,
          selected_city: data.selectedCity,
          eccommerce: {
            value: data.basket.totalPrice,
            currency: data.currency,
            items: data.basket.offers.flatMap((offer, index) =>
              this.mapBasketOfferToDataLayerItems({
                ...data,
                offer,
                context,
                index,
              })
            ),
          },
        });
      });
    } else {
      this.pushEvent(event, {});
    }
  }

  public pushPurchaseEvent(data: {
    selectedCity: string;
    selectedProductSegment: string;
    currency: string;
    basket: BasketModel;
    taxNumber: string;
    transactionId: string;
    paymentType: PaymentMethodEnum | null;
  }): void {
    window.dataLayer.push({ ecommerce: null });

    this.uiContextService.currentContext$.pipe(take(1)).subscribe(context => {
      this.pushEvent('purchase', {
        reservation_path: context,
        voucher_path: this.isVoucherPath(data.basket),
        selected_product_segment: data.selectedProductSegment,
        purchase_type: data.taxNumber ? 'Firma' : 'Prywatny',
        selected_city: data.selectedCity,
        eccommerce: {
          transaction_id: data.transactionId,
          payment_type: data.paymentType,
          value: data.basket.totalPrice,
          currency: data.currency,
          items: data.basket.offers.flatMap((offer, index) =>
            this.mapBasketOfferToDataLayerItems({
              ...data,
              offer,
              context,
              index,
            })
          ),
        },
      });
    });
  }

  public pushVoucherStartEvent(): void {
    this.uiContextService.currentContext$.pipe(take(1)).subscribe(context => {
      this.pushEvent('voucher_start', {
        reservation_path: context,
        voucher_path: 'yes',
      });
    });
  }

  public pushVoucherLocationEvent(data: {
    currency: string;
    vouchers: VoucherDto[];
    locations: CustomerLocationsListDto[];
  }): void {
    window.dataLayer.push({ ecommerce: null });

    this.uiContextService.currentContext$.pipe(take(1)).subscribe(context => {
      this.pushEvent('voucher_location_selection', {
        reservation_path: context,
        voucher_path: 'yes',
        eccommerce: {
          value: 0,
          currency: data.currency,
          items: data.vouchers.flatMap((voucher, index) =>
            voucher.products.map(product => ({
              item_id: voucher.id,
              item_name: voucher.offerName['pl'],
              affiliation: voucher.locationIds.map(
                locationId =>
                  data.locations.find(loc => loc.id === locationId)?.name?.[
                    'pl'
                  ]
              ),
              currency: data.currency,
              price: 0,
              discount: 0,
              index: index,
              item_brand: context,
              item_category: undefined,
              item_category2: undefined,
              item_category3: undefined,
              item_category4: undefined,
              item_category5: undefined,
              item_variant: product.productName['pl'],
              age_group_option: undefined,
              upsell_product: 'no',
              quantity: product.quantity,
              full_price: 0,
            }))
          ),
        },
      });
    });
  }

  public pushVoucherCalendarEvent(data: {
    selectedCity: string;
    currency: string;
    vouchers: VoucherDto[];
  }): void {
    window.dataLayer.push({ ecommerce: null });

    this.uiContextService.currentContext$.pipe(take(1)).subscribe(context => {
      this.pushEvent('reservation_date_selection', {
        reservation_path: context,
        voucher_path: 'yes',
        selected_product_segment: undefined,
        selected_city: data.selectedCity,
        eccommerce: {
          currency: data.currency,
          items: this.mapVouchersToDataLayerItems({ ...data, context }),
        },
      });
    });
  }

  public pushVoucherAddBasketItemEvent(data: {
    selectedCity: string;
    currency: string;
    vouchers: VoucherDto[];
  }): void {
    window.dataLayer.push({ ecommerce: null });

    this.uiContextService.currentContext$.pipe(take(1)).subscribe(context => {
      this.pushEvent('add_to_cart', {
        reservation_path: context,
        voucher_path: 'yes',
        selected_product_segment: undefined,
        selected_city: data.selectedCity,
        eccommerce: {
          value: 0,
          currency: data.currency,
          items: this.mapVouchersToDataLayerItems({ ...data, context }),
        },
      });
    });
  }

  public pushLoginEvent(data: { login_method: string }): void {
    this.pushEvent('login', {
      login_place: 'reservation_path',
      login_method: data.login_method,
    });
  }

  public pushSignUpEvent(): void {
    window.dataLayer.push({ event: 'sign_up' });
  }

  private mapProductsToDataLayerItems(data: {
    selectedCity: string;
    selectedProductSegment: string;
    currency: string;
    offer: CustomerOfferDetailsModel;
    selectedVariantProducts: VariantProductModel[];
    currentPrice: CurrentPriceModel;
    selectedVariantId: string;
    context: string;
  }): Record<string, unknown>[] {
    return data.selectedVariantProducts.map((product, index) => ({
      item_id: data.offer.id,
      item_name: data.offer.name['pl'],
      affiliation: data.selectedCity,
      currency: data.currency,
      price: undefined,
      discount: undefined,
      index: index,
      item_brand: data.context,
      item_category: data.selectedProductSegment,
      item_category2: data.selectedCity,
      item_category3: undefined,
      item_category4: undefined,
      item_category5: undefined,
      item_variant: data.offer.variants
        .find(variant => variant.id === data.selectedVariantId)
        ?.products.find(prod => prod.id === product.id)?.displayName['pl'],
      age_group_option: undefined,
      upsell_product: 'no',
      quantity: product.quantity,
      full_price: undefined,
    }));
  }

  private mapSelectedUpsellsToDataLayerItems(data: {
    selectedCity: string;
    selectedProductSegment: string;
    currency: string;
    offer: CustomerOfferDetailsModel;
    upsells: CustomerUpsellModel[];
    context: string;
    selectedUpsells: SelectedUpsellModel[];
  }): Record<string, unknown>[] {
    return data.selectedUpsells.map((selectedUpsell, index) => {
      const upsell = data.upsells.find(u => u.id === selectedUpsell.id);

      if (!upsell) {
        throw new Error('Upsell not found');
      }

      return {
        item_id: upsell.id,
        item_name: upsell.name['pl'],
        affiliation: data.selectedCity,
        currency: data.currency,
        price: upsell.price,
        discount: 0,
        index: index,
        item_brand: data.context,
        item_category: data.selectedProductSegment,
        item_category2: data.selectedCity,
        item_category3: undefined,
        item_category4: undefined,
        item_category5: undefined,
        item_variant: undefined,
        age_group_option: undefined,
        upsell_product: 'yes',
        quantity: selectedUpsell.quantity,
        full_price: upsell.price,
      };
    });
  }

  private mapBasketOfferToDataLayerItems(data: {
    selectedCity: string;
    selectedProductSegment: string;
    currency: string;
    offer: BasketOfferModel;
    context: string;
    index: number;
  }): Record<string, unknown>[] {
    const products = data.offer.allProducts.map(product => ({
      item_id: data.offer.offerId,
      item_name: data.offer.name['pl'],
      affiliation: data.offer.locations.map(location => location.name),
      currency: data.currency,
      price: product.totalPrice,
      discount: product.originalPrice - product.totalPrice,
      index: data.index,
      item_brand: data.context,
      item_category: data.selectedProductSegment,
      item_category2: data.offer.locations.map(location => location.name['pl']),
      item_category3: undefined,
      item_category4: undefined,
      item_category5: undefined,
      item_variant: product.name['pl'],
      age_group_option: undefined,
      upsell_product: 'no',
      quantity: product.quantity,
      full_price: product.originalPrice,
    }));

    const upsells = data.offer.upsells.map(upsell => ({
      item_id: data.offer.offerId,
      item_name: data.offer.name['pl'],
      affiliation: data.offer.locations.map(location => location.name),
      currency: data.currency,
      price: upsell.totalPrice,
      discount: 0,
      index: data.index,
      item_brand: data.context,
      item_category: data.selectedProductSegment,
      item_category2: data.offer.locations.map(location => location.name['pl']),
      item_category3: undefined,
      item_category4: undefined,
      item_category5: undefined,
      item_variant: upsell.name['pl'],
      age_group_option: undefined,
      upsell_product: 'yes',
      quantity: upsell.quantity,
      full_price: upsell.totalPrice,
    }));

    return products.concat(upsells);
  }

  private mapVouchersToDataLayerItems(data: {
    selectedCity: string;
    currency: string;
    vouchers: VoucherDto[];
    context: string;
  }): Record<string, unknown>[] {
    return data.vouchers.flatMap((voucher, index) =>
      voucher.products.map(product => ({
        item_id: voucher.id,
        item_name: voucher.offerName['pl'],
        affiliation: data.selectedCity,
        currency: data.currency,
        price: 0,
        discount: 0,
        index: index,
        item_brand: data.context,
        item_category: data.selectedCity,
        item_category2: undefined,
        item_category3: undefined,
        item_category4: undefined,
        item_category5: undefined,
        item_variant: product.productName['pl'],
        age_group_option: undefined,
        upsell_product: 'no',
        quantity: product.quantity,
        full_price: 0,
      }))
    );
  }

  private isVoucherPath(basket: BasketModel): 'yes' | 'no' {
    return basket.offers.every(
      offer => offer.kind === BasketItemSourceEnum.Voucher
    )
      ? 'yes'
      : 'no';
  }
}
