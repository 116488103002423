import {
  UserOrderDetailsDto,
  PaymentMethodEnum,
  ProductKindEnum,
} from '@xspot-app/common';
import { UserOrderDetailsModel, UserOrderProductDetailsModel } from '../domain';

export function mapUserOrderDetailsDtoToModel(
  order: UserOrderDetailsDto
): UserOrderDetailsModel {
  const paymentMethod = mapPaymentMethod(order);

  return {
    ...order,
    paymentMethodKey: paymentMethod,
    items: order.items.map(item => {
      const reservationProducts = item.products
        .filter(product => product.kind === ProductKindEnum.Reservation)
        .map(product => ({
          ...product,
          price: product.price * product.quantity,
          date: product.slots[0]?.date ?? null,
          from: product.slots[0]?.from ?? null,
          to: product.slots[0]?.to ?? null,
          numberOfMinutes: product.slots[0]?.numberOfMinutes ?? null,
        }));

      const groupedReservationProducts = reservationProducts.reduce<
        Record<string, UserOrderProductDetailsModel[]>
      >((acc, product) => {
        if (acc[product.date]) {
          acc[product.date].push(product);
        } else {
          acc[product.date] = [product];
        }

        return acc;
      }, {});

      return {
        ...item,
        groupedReservationProducts,
        reservationProducts: reservationProducts,
        nonReservationProducts: item.products
          .filter(product => product.kind !== ProductKindEnum.Reservation)
          .map(product => ({
            ...product,
            price: product.price * product.quantity,
            date: null,
            from: null,
            to: null,
            numberOfMinutes: null,
          })),
        isMinimalAmount: item.products.some(product => product.isMinimalAmount),
      };
    }),
  };
}

export function mapPaymentMethod(order: UserOrderDetailsDto): string {
  const paymentMethodsList = order.items.reduce<PaymentMethodEnum[]>(
    (paymentMethods, item) => {
      return paymentMethods.concat(
        ...item.products.reduce<PaymentMethodEnum[]>(
          (acc, product) => acc.concat(product.paymentMethod),
          []
        )
      );
    },
    []
  );

  let paymentMethod = 'common.paymentMethodEnum.PaymentProvider.title';

  if (new Set(paymentMethodsList).size === 1) {
    const methods = {
      [PaymentMethodEnum.PaymentProvider]:
        'common.paymentMethodEnum.PaymentProvider.title',
      [PaymentMethodEnum.Credit]: 'common.paymentMethodEnum.Minutes.title',
      [PaymentMethodEnum.Voucher]: 'userProfile.orderModal.voucher',
      [PaymentMethodEnum.DelayedCredit]:
        'common.paymentMethodEnum.DelayedCredit.title',
    };

    paymentMethod = methods[paymentMethodsList[0]];
  } else if (
    paymentMethodsList.includes(PaymentMethodEnum.PaymentProvider) &&
    paymentMethodsList.includes(PaymentMethodEnum.Credit)
  ) {
    paymentMethod = 'common.paymentMethodEnum.OnlineAndMinutes.title';
  } else if (
    paymentMethodsList.includes(PaymentMethodEnum.Voucher) &&
    paymentMethodsList.includes(PaymentMethodEnum.Credit)
  ) {
    paymentMethod = 'common.paymentMethodEnum.Minutes.title';
  }

  return paymentMethod;
}
