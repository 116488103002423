import { PaymentStatus, UiContext } from '../../enums';

export interface UserOrderDto {
  id: string;
  number: number;
  format: UiContext;
  createdAt: string;
  price: number;
  status: PaymentStatus;
  paymentUrl: string;
}
