import {
  UiContext,
  PaymentMethodEnum,
  PaymentStatus,
  LanguageControlGroup,
  ProductKindEnum,
  Nullable,
} from '@xspot-app/common';

export interface UserOrderDetailsModel {
  id: string;
  number: number;
  format: UiContext;
  paymentMethod: PaymentMethodEnum;
  paymentMethodKey: string;
  createdAt: string;
  status: PaymentStatus;
  items: UserOrderItemDetailsModel[];
}

export interface UserOrderItemDetailsModel {
  id: string;
  number: number;
  paymentMethod: PaymentMethodEnum;
  price: number;
  offerName: LanguageControlGroup;
  offerVariantName: LanguageControlGroup;
  locationNames: LanguageControlGroup[];
  reservationProducts: UserOrderProductDetailsModel[];
  nonReservationProducts: UserOrderProductDetailsModel[];
  groupedReservationProducts: Record<string, UserOrderProductDetailsModel[]>;
  isMinimalAmount: boolean;
}

export interface UserOrderProductDetailsModel {
  id: string;
  kind: ProductKindEnum;
  paymentMethod: PaymentMethodEnum;
  price: number;
  quantity: number;
  isMinimalAmount: boolean;
  productDisplayName: LanguageControlGroup;
  date: Nullable<string>;
  from: Nullable<string>;
  to: Nullable<string>;
  numberOfMinutes: Nullable<number>;
}
