<div *transloco="let t" class="w-full h-full flex flex-column gap-3">
  <p-table
    [value]="formArray.controls"
    [reorderableColumns]="true"
    (onRowReorder)="onRowReorderHandler()">
    <ng-template pTemplate="header">
      <tr>
        <th></th>
        <th class="w-2">{{ t('ui.fileUpload.id') }}</th>
        <th class="w-full">{{ t('ui.fileUpload.name') }}</th>
        <th></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-element let-index="rowIndex">
      <tr [pReorderableRow]="index">
        <td>
          @if (!previewMode) {
            <i class="pi pi-bars" pReorderableRowHandle></i>
          }
        </td>
        <td class="font-semibold">{{ element.controls.ordinal.value + 1 }}.</td>
        <td>{{ element.controls.name.value }}</td>
        <td class="flex">
          @if (!previewMode) {
            <p-button
              icon="pi pi-download text-900"
              severity="secondary"
              [rounded]="true"
              [text]="true"
              (onClick)="downloadFile(element.controls.uri.value)"></p-button>
            <p-button
              icon="pi pi-trash text-900"
              severity="secondary"
              [rounded]="true"
              [text]="true"
              (onClick)="removeFile(index)"></p-button>
          }
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="4" class="text-center p-4">
          {{ t('ui.fileUpload.noFiles') }}
        </td>
      </tr>
    </ng-template>
  </p-table>
  @if (!previewMode) {
    <input
      #inputLegalFile
      type="file"
      class="hidden"
      accept=".pdf"
      (change)="uploadLegalFile($event)" />
    <p-button
      class="align-self-end"
      type="button"
      icon="pi pi-plus"
      (click)="inputLegalFile.click()"
      [disabled]="formArray.length >= maxFilesCount"
      [loading]="isLoading$ | async"
      [label]="t('ui.fileUpload.addFile')"></p-button>
    <small class="text-error" *ngIf="errorMessage$ | async as errorMessage">
      {{ t('ui.' + errorMessage.summary, { MaxFileSize: MaxFileSize_MB }) }}
    </small>
  }
</div>
