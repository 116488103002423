import {
  isSameMonthAndYear,
  LanguageControlGroup,
  Nullable,
} from '@xspot-app/common';
import { CalendarDaysModel } from '../../domain';
import {
  CalendarDaysDto,
  CalendarDaysVoucherDto,
  OfferPromotionDto,
} from '../../dtos';
import { DateTime } from 'luxon';

export class CalendarDaysModelMapper {
  public static map(
    calendarDaysDto: CalendarDaysDto | CalendarDaysVoucherDto,
    calendarYearMonth: DateTime,
    promotions: Nullable<OfferPromotionDto[]> = null
  ): CalendarDaysModel {
    return {
      ...calendarDaysDto,
      days: calendarDaysDto.days.map(day => {
        let matchedPromotions: OfferPromotionDto[] = [];
        if ('productPromotionIds' in day && promotions?.length) {
          matchedPromotions = day.productPromotionIds
            .map(promoId => promotions.find(p => p.id === promoId))
            .filter(Boolean) as OfferPromotionDto[];
        }

        const productPromotionName: Nullable<LanguageControlGroup> =
          matchedPromotions.length > 0
            ? matchedPromotions[0].displayName
            : null;

        const hasAnyPromotion =
          !!productPromotionName ||
          (day.actualPrice != null && day.originalPrice != null
            ? day.actualPrice < day.originalPrice
            : false);
        const date: DateTime = DateTime.fromFormat(day.date, 'yyyy-MM-dd');
        return {
          ...day,
          date,
          isCurrentMonth: isSameMonthAndYear(date, calendarYearMonth),
          productPromotionName,
          hasAnyPromotion,
        };
      }),
    };
  }
}
