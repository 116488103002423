import { BasketModel } from '@xspot-app/common';
import { CreateBasketVoucherItemDto, UpsertBasketItemDto } from '../../dtos';
import { DiscountCodeModel } from '../../domain';

export namespace Baskets {
  export class GetItems {
    public static readonly type = '[Baskets] Get items';
  }

  export class AddItem {
    public static readonly type = '[Baskets] Add item';
    constructor(public payload: UpsertBasketItemDto) {}
  }

  export class AddVoucherItem {
    public static readonly type = '[Baskets] Add voucher item';
    constructor(public payload: CreateBasketVoucherItemDto) {}
  }

  export class UpdateItem {
    public static readonly type = '[Baskets] Update item';
    constructor(public payload: UpsertBasketItemDto) {}
  }

  export class UpdateCurrent {
    public static readonly type = '[Baskets] Update current';
    constructor(public payload: BasketModel) {}
  }

  export class AddDiscountCode {
    public static readonly type = '[Baskets] Add Discount Code';
    constructor(public payload: DiscountCodeModel) {}
  }

  export class RemoveDiscountCode {
    public static readonly type = '[Baskets] Remove Discount Code';
  }

  export class DeleteItem {
    public static readonly type = '[Baskets] Delete item';
    constructor(public id: string) {}
  }

  export class ClearBasketsState {
    public static readonly type = '[Baskets] Clear baskets state';
  }
}
