import { FlyspotB2BStatusEnum, Language } from '@xspot-app/common';
import { UserAgreementModel } from './user-agreement.model';

export interface UserProfileModel {
  email: string;
  name: string;
  surname: string;
  canUseDelayedPayment: boolean;
  dateOfBirth: string | null;
  language: Language;
  emailConfirmed: boolean | null;
  phoneNumberPrefix: string;
  phoneNumberWithoutPrefix: string;
  agreements: UserAgreementModel[];
  flyspotB2B: FlyspotB2BStatusEnum;
  deepspotB2B: string; // string of comma separated DeepspotB2BStatusEnum
}
