import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { provideTranslocoScope, TranslocoDirective } from '@ngneat/transloco';
import {
  ErrorMessage,
  getErrorMessage,
  MaxFileSize_MB,
  Nullable,
  RecommendedAspectRatio,
  scopeLoaderFactory,
  StringUtil,
} from '@xspot-app/common';
import { ButtonModule } from 'primeng/button';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import { DragAndDropDirective } from '../../directives';
import { FormControlErrorsComponent } from '../form-control-errors/form-control-errors.component';
import { CoverImageControlGroup } from '../../models';
import { FilesLibraryUploadService } from '../../services';
import { FileUploadErrorCodes, FileUploadErrorMessages } from '../../consts';
import { LibraryFilesDto } from '../../dtos';

@UntilDestroy()
@Component({
  selector: 'xspot-app-cover-image',
  standalone: true,
  imports: [
    CommonModule,
    TranslocoDirective,
    ButtonModule,
    ReactiveFormsModule,
    DragAndDropDirective,
    FormControlErrorsComponent,
  ],
  templateUrl: './cover-image.component.html',
  styleUrl: './cover-image.component.scss',
  providers: [
    provideTranslocoScope({
      scope: 'ui',
      loader: scopeLoaderFactory(
        (lang: string) => import(`../../i18n/${lang}.json`)
      ),
    }),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoverImageComponent {
  @Input({ required: true })
  public coverFormGroup!: FormGroup<CoverImageControlGroup>;

  protected fileOver: boolean = false;
  protected MaxFileSize_MB = MaxFileSize_MB;
  protected RecommendedAspectRatio = RecommendedAspectRatio;
  protected isLoading$ = new BehaviorSubject<boolean>(false);
  protected errorMessage$ = new BehaviorSubject<Nullable<ErrorMessage>>(null);

  constructor(private filesLibraryUploadService: FilesLibraryUploadService) {}

  protected upload(event: Event): void {
    const fileInput = event.target as HTMLInputElement;

    if (!fileInput.files || !fileInput.files[0]) return;

    const file = fileInput.files[0];
    this.sendFile(file);
    fileInput.value = StringUtil.empty;
  }

  protected uploadFiles(files: FileList): void {
    const file = files[0];
    this.sendFile(file);
  }

  private sendFile(file: File): void {
    this.errorMessage$.next(null);
    if (file.size / 1024 / 1024 > MaxFileSize_MB) {
      this.errorMessage$.next(
        getErrorMessage(
          FileUploadErrorCodes.ValidationFailed,
          FileUploadErrorMessages,
          () => 'error'
        )
      );
      return;
    }

    this.isLoading$.next(true);
    this.filesLibraryUploadService
      .upload([file])
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (result: LibraryFilesDto) => {
          this.isLoading$.next(false);
          this.coverFormGroup.controls.fileId.setValue(result[0].id);
          this.coverFormGroup.controls.uri.setValue(result[0].uri);
        },
        error: ({ error }) => {
          this.isLoading$.next(false);
          this.errorMessage$.next(
            getErrorMessage(
              error.errorCode,
              FileUploadErrorMessages,
              () => 'error'
            )
          );
        },
      });
  }
}
