import { CustomerPriceDto, LanguageControlGroup } from '../dtos';
import { OfferCategoryEnum, OfferProductKindEnum } from '../enums';

export interface CustomerOfferModel {
  id: string;
  coverMobileUri: string;
  coverDesktopUri: string;
  name: LanguageControlGroup;
  teaser: LanguageControlGroup;
  ordinal: number;
  price: CustomerPriceDto;
  isMinimumPrice: boolean;
  isPricePerMinute: boolean;
  categories: OfferCategoryEnum[];
  locationIds: string[];
  hasAnyPromotion?: boolean;
  offerProductKind: OfferProductKindEnum;
}
