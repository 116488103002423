import { Nullable } from '@xspot-app/common';

export interface GetSelectedProductsMinimalPriceDto {
  offerVariantId: string;
  locationId: Nullable<string>;
  selectedProducts: SelectedOfferVariantProductDto[];
}

export interface SelectedOfferVariantProductDto {
  offerVariantProductId: string;
  quantity: number;
}
