import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { TranslocoDirective, TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngxs/store';
import { DialogModule } from 'primeng/dialog';
import {
  CustomerLocationsListDto,
  PromotionKindEnum,
  XTooltipDirective,
} from '@xspot-app/common';
import { combineLatest, map, Observable, BehaviorSubject } from 'rxjs';
import { LocationsState } from '@xspot-app/customer/locations-api';
import { OfferPromotionModel } from '../../domain';
import { OffersState } from '../../state';
import { CurrencyFormatPipe, JoinPipe } from '@xspot-app/shared/ui';
import { DateTime } from 'luxon';

@Component({
  selector: 'xspot-app-offer-promotion',
  standalone: true,
  imports: [
    ButtonModule,
    DialogModule,
    CommonModule,
    JoinPipe,
    TranslocoDirective,
    XTooltipDirective,
    CurrencyFormatPipe,
  ],
  templateUrl: './offer-promotion.component.html',
  styleUrls: ['./offer-promotion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OfferPromotionComponent {
  @Input() public styleClass: string = '';
  @Input() public buttonClass: string = '';

  private _filterDate?: DateTime;
  @Input()
  public set filterDate(date: DateTime | undefined) {
    this._filterDate = date;
    this.filterDateSubject.next(date);
  }
  public get filterDate(): DateTime | undefined {
    return this._filterDate;
  }

  protected promotions$: Observable<OfferPromotionModel[]> = this.store.select(
    OffersState.promotions
  );

  protected calculatePercentage(value: number | undefined): number {
    if (!value) {
      return 0;
    }

    return parseInt((value * 100).toFixed(0), 10);
  }

  protected locations$: Observable<CustomerLocationsListDto[]> =
    this.store.select(LocationsState.all);

  private filterDateSubject = new BehaviorSubject<DateTime | undefined>(
    this._filterDate
  );

  protected lang$ = this.translocoService.langChanges$;
  protected isVisible: boolean = false;
  protected promotionKindEnum = PromotionKindEnum;

  protected enhancedPromotions$: Observable<OfferPromotionModel[]> =
    combineLatest([
      this.promotions$,
      this.locations$,
      this.filterDateSubject,
    ]).pipe(
      map(([promotions, locations, filterDate]) => {
        let filteredPromotions = promotions;

        if (filterDate) {
          const monthStart = filterDate.startOf('month');
          const monthEnd = filterDate.endOf('month');

          filteredPromotions = promotions.filter(promotion => {
            const from = DateTime.fromISO(promotion.validFromDate);
            const to = DateTime.fromISO(promotion.validToDate);

            return (
              to.toMillis() >= monthStart.toMillis() &&
              from.toMillis() <= monthEnd.toMillis()
            );
          });
        }

        return filteredPromotions.map(promotion => ({
          ...promotion,
          locationNames: promotion.locationIds.map(
            locationId =>
              locations.find(location => location.id === locationId)?.name ?? {}
          ),
        }));
      })
    );

  constructor(
    private store: Store,
    private translocoService: TranslocoService
  ) {}

  protected getProductDisplayNames(
    promotion: OfferPromotionModel
  ): Array<Record<string, string>> {
    return promotion?.products?.map(product => product.displayName) ?? [];
  }

  protected toggleModal(): void {
    this.isVisible = true;
  }
}
