import {
  CustomerOfferDetailsModel,
  CustomerOfferDetailsVariantProductVolumePriceProductModel,
  CustomerOfferDetailsVariantVolumePriceModel,
  VolumePriceDiscountKindEnum,
} from '@xspot-app/common';
import {
  CustomerOfferDetailsDto,
  CustomerOfferDetailsVariantProductDto,
} from '../dtos';

export class CustomerOfferModelMapper {
  public static map(dto: CustomerOfferDetailsDto): CustomerOfferDetailsModel {
    const files = dto.legalFiles.concat(
      dto.variants.flatMap(variant =>
        variant.products.flatMap(
          product => product.volumePrice?.documents || []
        )
      )
    );

    const uniqueFiles = [
      ...new Map(files.map(file => [file.uri, file])).values(),
    ];

    return {
      ...dto,
      files: uniqueFiles,
      variants: dto.variants.map(variant => ({
        ...variant,
        volumePrices: this.mapVolumePrices(
          variant.products.flatMap(p => (p.volumePrice ? [p] : []))
        ),
      })),
    };
  }

  private static mapVolumePrices(
    productsWithVolumePrice: CustomerOfferDetailsVariantProductDto[]
  ): CustomerOfferDetailsVariantVolumePriceModel[] {
    return [
      ...new Map(
        productsWithVolumePrice
          .flatMap(p => p.volumePrice!)
          .map(volumePrice => [
            volumePrice.id,
            {
              ...volumePrice,
              items: volumePrice.items
                .sort((a, b) => a.from - b.from)
                .map(item => {
                  return {
                    ...item,
                    discountValue:
                      {
                        [VolumePriceDiscountKindEnum.Percent]:
                          item.discountValue * 100,
                        [VolumePriceDiscountKindEnum.Price]: item.discountValue,
                      }[volumePrice.discountKind] || item.discountValue,
                  };
                }),
              products: productsWithVolumePrice
                .filter(p => p.volumePrice!.id === volumePrice.id)
                .flatMap(p => [
                  {
                    id: p.id,
                    displayName: p.displayName,
                  } as CustomerOfferDetailsVariantProductVolumePriceProductModel,
                ]),
              selectedProductCount: 0,
              isMaximumThresholdReached: false,
            } as CustomerOfferDetailsVariantVolumePriceModel,
          ])
      ).values(),
    ];
  }
}
