import {
  PaymentMethodEnum,
  PaymentStatus,
  ProductKindEnum,
  UiContext,
} from '../../enums';
import { LanguageControlGroup } from '../language-control-group.type';

export interface UserOrderDetailsDto {
  id: string;
  number: number;
  format: UiContext;
  paymentMethod: PaymentMethodEnum;
  createdAt: string;
  status: PaymentStatus;
  items: UserOrderItemDetailsDto[];
}

export interface UserOrderItemDetailsDto {
  id: string;
  number: number;
  paymentMethod: PaymentMethodEnum;
  price: number;
  offerName: LanguageControlGroup;
  offerVariantName: LanguageControlGroup;
  locationNames: LanguageControlGroup[];
  products: UserOrderProductDetailsDto[];
}

export interface UserOrderProductDetailsDto {
  id: string;
  kind: ProductKindEnum;
  paymentMethod: PaymentMethodEnum;
  price: number;
  quantity: number;
  isMinimalAmount: boolean;
  productDisplayName: LanguageControlGroup;
  slots: UserOrderProductSlotDetailsDto[];
}

export interface UserOrderProductSlotDetailsDto {
  date: string;
  from: string;
  to: string;
  numberOfMinutes: number;
}
