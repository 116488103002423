import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { catchError, EMPTY, map, Observable, tap } from 'rxjs';
import { User } from './user.actions';
import {
  GusCompanyDataModel,
  UserAgreementModel,
  UserProfileModel,
  UserStateModel,
} from '../domain';
import { produce } from 'immer';
import {
  GusCompanyDataDto,
  Nullable,
  pagedListDefaults,
  StringUtil,
  UserCreditHistoryListDto,
  UserCreditsDto,
  UserCreditsHeaderDto,
  UserOrderDetailsDto,
  UserOrdersDto,
  UserProfileDto,
  UserReservationDetailsDto,
  UserReservationsDto,
  UserService,
} from '@xspot-app/common';
import { mapUserOrderDetailsDtoToModel } from '../helpers';

const USER_STATE_TOKEN = new StateToken<UserStateModel>('user');

@State<UserStateModel>({
  name: USER_STATE_TOKEN,
  defaults: {
    user: null,
    profile: null,
    gusCompanyData: null,
    orders: pagedListDefaults,
    orderDetails: {},
    reservations: pagedListDefaults,
    selectedReservation: null,
    userCredits: pagedListDefaults,
    creditHistory: pagedListDefaults,
    userCreditsHeader: null,
  },
})
@Injectable()
export class UserState {
  constructor(private userService: UserService) {}

  @Selector()
  public static profile(state: UserStateModel): UserProfileModel | null {
    return state.profile;
  }

  @Selector()
  public static canUseDelayedPayment(state: UserStateModel): boolean {
    return !!state.profile?.canUseDelayedPayment;
  }

  @Selector()
  public static agreements(state: UserStateModel): UserAgreementModel[] | null {
    return state.profile?.agreements ?? [];
  }

  @Selector()
  public static gusData(state: UserStateModel): GusCompanyDataModel | null {
    return state.gusCompanyData;
  }

  @Selector()
  public static orders(state: UserStateModel): UserOrdersDto {
    return state.orders;
  }

  @Selector()
  public static orderDetails(state: UserStateModel) {
    return (id: string) => {
      return state.orderDetails[id];
    };
  }

  @Selector()
  public static reservations(state: UserStateModel): UserReservationsDto {
    return state.reservations;
  }

  @Selector()
  public static selectedReservation(
    state: UserStateModel
  ): Nullable<UserReservationDetailsDto> {
    return state.selectedReservation;
  }

  @Selector()
  public static userCredits(state: UserStateModel): UserCreditsDto {
    return state.userCredits;
  }

  @Selector()
  public static userCreditsHeader(
    state: UserStateModel
  ): Nullable<UserCreditsHeaderDto> {
    return state.userCreditsHeader;
  }

  @Selector()
  public static creditHistory(state: UserStateModel): UserCreditHistoryListDto {
    return state.creditHistory;
  }

  @Action(User.Create)
  public create(
    ctx: StateContext<UserStateModel>,
    action: User.Create
  ): Observable<void> {
    return this.userService.create(action.payload);
  }

  @Action(User.CreateExternal)
  public createExternal(
    ctx: StateContext<UserStateModel>,
    action: User.CreateExternal
  ): Observable<void> {
    return this.userService.createExternalUser(action.payload);
  }

  @Action(User.FetchProfile)
  public fetchProfile({
    patchState,
  }: StateContext<UserStateModel>): Observable<void> {
    return this.userService.fetchProfile().pipe(
      map((payload: UserProfileDto) => {
        patchState({ profile: { ...payload } });
        return;
      })
    );
  }

  @Action(User.UpdateProfile)
  public updateProfile(
    ctx: StateContext<UserStateModel>,
    { payload }: User.UpdateProfile
  ): Observable<void> {
    return this.userService.updateProfile(payload).pipe(
      tap(() => {
        const state = produce(ctx.getState(), draft => {
          draft.profile = {
            ...draft.profile!,
            ...payload,
          };
        });

        ctx.setState(state);
      })
    );
  }

  @Action(User.UpdateAgreements)
  public updateAgreements(
    ctx: StateContext<UserStateModel>,
    action: User.UpdateAgreements
  ): Observable<void> {
    return this.userService.updateAgreements(action.payload);
  }

  @Action(User.GetGusCompanyData, { cancelUncompleted: true })
  public getGusCompanyData(
    ctx: StateContext<UserStateModel>,
    action: User.GetGusCompanyData
  ): Observable<GusCompanyDataDto> {
    return this.userService.getGusCompanyData(action.taxNumber).pipe(
      tap((response: GusCompanyDataDto) => {
        const street = response.street
          ? `${response.street} ${response.streetNumber ?? StringUtil.empty}`
          : StringUtil.empty;

        const model: GusCompanyDataModel = {
          taxNumber: response.taxNumber,
          name: response.name,
          city: response.city ?? StringUtil.empty,
          postalCode: response.postalCode ?? StringUtil.empty,
          street: street.trim(),
          number: response.flatNumber ?? StringUtil.empty,
        };
        ctx.patchState({ gusCompanyData: model });
      })
    );
  }

  @Action(User.GetUserOrders)
  public getUserOrders(
    ctx: StateContext<UserStateModel>,
    { payload }: User.GetUserOrders
  ): Observable<UserOrdersDto> {
    return this.userService.getUserOrders(payload).pipe(
      tap(orders => {
        ctx.patchState({ orders });
      })
    );
  }

  @Action(User.GetUserOrderDetails)
  public getUserOrderDetails(
    ctx: StateContext<UserStateModel>,
    { orderId }: User.GetUserOrderDetails
  ): Observable<UserOrderDetailsDto> {
    return this.userService.getUserOrderDetails(orderId).pipe(
      tap(order => {
        const state = produce(ctx.getState(), draft => {
          draft.orderDetails[orderId] = mapUserOrderDetailsDtoToModel(order);
        });
        ctx.setState(state);
      })
    );
  }

  @Action(User.GetUserReservations)
  public getUserReservations(
    ctx: StateContext<UserStateModel>,
    { payload }: User.GetUserReservations
  ): Observable<UserReservationsDto> {
    return this.userService.getReservations(payload).pipe(
      tap(reservations => {
        ctx.patchState({ reservations });
      })
    );
  }

  @Action(User.GetUserReservationDetails)
  public getUserReservationDetails(
    ctx: StateContext<UserStateModel>,
    { payload }: User.GetUserReservationDetails
  ): Observable<UserReservationDetailsDto> {
    if (!payload.id) {
      return EMPTY;
    }

    return this.userService.getReservation(payload).pipe(
      tap(selectedReservation => {
        ctx.patchState({ selectedReservation });
      }),
      catchError(() => {
        ctx.patchState({ selectedReservation: null });
        return EMPTY;
      })
    );
  }

  @Action(User.GetUserCredits)
  public getUserCredits(
    ctx: StateContext<UserStateModel>,
    { payload }: User.GetUserCredits
  ): Observable<UserCreditsDto> {
    return this.userService.getUserCredits(payload).pipe(
      tap(credits => {
        ctx.patchState({ userCredits: credits });
      })
    );
  }

  @Action(User.GetUserCreditsHeader)
  public getUserCreditsHeader(
    ctx: StateContext<UserStateModel>
  ): Observable<UserCreditsHeaderDto> {
    return this.userService.getUserCreditsHeader().pipe(
      tap(header => {
        ctx.patchState({ userCreditsHeader: header });
      })
    );
  }

  @Action(User.GetUserCreditHistory)
  public getUserCreditDetails(
    ctx: StateContext<UserStateModel>,
    { creditId, params }: User.GetUserCreditHistory
  ): Observable<UserCreditHistoryListDto> {
    return this.userService.getUserCreditHistory(creditId, params).pipe(
      tap(creditHistory => {
        ctx.patchState({ creditHistory });
      })
    );
  }
}
