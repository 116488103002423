import { Nullable, UiContext } from '@xspot-app/common';

export interface GetOfferUpsellsDto {
  basketId: Nullable<string>;
  basketItemId: Nullable<string>;
  locationIds: string[];
  products: GetOfferUpsellsProductDto[];
  format: UiContext;
}

export interface GetOfferUpsellsProductDto {
  offerVariantProductId: string;
  quantity: number;
  slot?: GetOfferUpsellsSlotTypeDto;
}

export interface GetOfferUpsellsSlotTypeDto {
  date: string;
  from: string;
  to: string;
}
