import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  CustomerOfferDetailsDto,
  CustomerOfferDto,
  GetOfferUpsellsDto,
  GetSelectedProductsMinimalPriceDto,
  OfferPromotionDto,
  SelectedProductsMinimalPriceDto,
  UpsellDto,
} from '../dtos';
import { environment } from '@xspot-app/common';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class OffersService {
  constructor(
    private http: HttpClient,
    private route: ActivatedRoute
  ) {}

  public getActiveOffers(): Observable<CustomerOfferDto[]> {
    return this.http.get<CustomerOfferDto[]>(
      `${environment.apiUrl}/api/offers`
    );
  }

  public getOffer(id: string): Observable<CustomerOfferDetailsDto> {
    let headers = new HttpHeaders();
    const { backOfficeToken } = this.route.snapshot.queryParams;

    if (backOfficeToken) {
      headers = headers.set('X-BackOfficeOfferToken', backOfficeToken);
    }

    return this.http.get<CustomerOfferDetailsDto>(
      `${environment.apiUrl}/api/offers/${id}`,
      { headers }
    );
  }

  public getOfferPromotion(id: string): Observable<OfferPromotionDto[]> {
    return this.http.get<OfferPromotionDto[]>(
      `${environment.apiUrl}/Api/Offers/${id}/ActivePromotions`
    );
  }

  public getMinimalPrice(
    offerId: string,
    payload: GetSelectedProductsMinimalPriceDto
  ): Observable<SelectedProductsMinimalPriceDto> {
    let headers = new HttpHeaders();
    const { backOfficeToken } = this.route.snapshot.queryParams;

    if (backOfficeToken) {
      headers = headers.set('X-BackOfficeOfferToken', backOfficeToken);
    }

    return this.http.post<SelectedProductsMinimalPriceDto>(
      `${environment.apiUrl}/api/offers/${offerId}/MinimalPrice`,
      payload,
      { headers }
    );
  }

  public getOfferUpsells(
    offerId: string,
    payload: GetOfferUpsellsDto
  ): Observable<UpsellDto[]> {
    return this.http.post<UpsellDto[]>(
      `${environment.apiUrl}/api/offers/${offerId}/upsells`,
      payload
    );
  }
}
