import {
  BasketItemSourceEnum,
  CodesKind,
  CreditKindEnum,
  ProductKindEnum,
} from '../enums';
import { Nullable } from '../helpers';
import { LanguageControlGroup } from './language-control-group.type';

export interface BasketDto {
  actualPrice: number;
  originalPrice: number;
  appliedDiscountCode: string;
  appliedDiscountCodeType: CodesKind;
  items: BasketOfferDto[];
  creditPaymentInfo?: CreditPaymentInfoDto;
}

export interface CreditPaymentInfoDto {
  canBePaidByCredit: boolean;
  isEnoughCreditAmount?: boolean;
  amountToPay?: number;
  creditAmountToPay?: number;
  canBePaidByDelayPayment?: boolean;
}

export interface BasketOfferDto {
  id: string;
  offerId: string;
  offerVariantId: string;
  actualPrice: number;
  originalPrice: number;
  offerName: LanguageControlGroup;
  offerVariantName: LanguageControlGroup;
  locationIds: string[];
  products: BasketOfferProductDto[];
  brokerCodeBonus: BrokerCodeBonusDto;
  upsells: BasketOfferUpsellDto[];
  coverDesktopUri: string;
  coverMobileUri: string;
  kind: BasketItemSourceEnum;
  voucherId: Nullable<string>;
  voucherValidTo: Nullable<string>;
  voucherCode: string;
}

export interface BrokerCodeBonusDto {
  bonusMinutes: number;
  locationIds: string[];
  creditKind: CreditKindEnum;
}

export interface BasketOfferProductDto {
  id: string;
  offerVariantProductId: string;
  actualPrice: number;
  originalPrice: number;
  productDisplayName: LanguageControlGroup;
  quantity: number;
  date: Nullable<string>;
  from: Nullable<string>;
  to: Nullable<string>;
  isMinimalAmount: boolean;
  numberOfMinutes: Nullable<number>;
  productKind: ProductKindEnum;
  promotionProducts?: BasketPromotionProductDto[];
}

export interface BasketPromotionProductDto {
  id: string;
  displayName: LanguageControlGroup;
}

export interface BasketOfferUpsellDto {
  id: string;
  upsellId: string;
  actualPrice: number;
  originalPrice: number;
  upsellName: LanguageControlGroup;
  quantity: number;
}
