import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment, VoucherDto } from '@xspot-app/common';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class VoucherService {
  constructor(private http: HttpClient) {}

  public getVoucher(voucherCodes: string[]): Observable<VoucherDto[]> {
    let params = new HttpParams();

    voucherCodes.forEach(code => {
      params = params.append('voucherCode', code);
    });

    return this.http.get<VoucherDto[]>(`${environment.apiUrl}/api/Vouchers`, {
      params,
    });
  }
}
