import { AbstractControl } from '@angular/forms';

export function appendArray<T extends string | Blob>(
  formData: FormData,
  key: string,
  values: T[]
): void {
  values.forEach(value => {
    formData.append(key, value);
  });
}

export function toggleControl(
  control: AbstractControl | null,
  enable: boolean
): void {
  if (!control) {
    return;
  }
  if (enable) {
    control.enable();
  } else {
    control.disable();
  }
}
