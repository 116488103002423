import { ChangeDetectorRef, OnInit, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AbstractControl } from '@angular/forms';
import { provideTranslocoScope, TranslocoPipe } from '@ngneat/transloco';
import { scopeLoaderFactory } from '@xspot-app/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SafeHtmlPipe } from '../../pipes';

@UntilDestroy()
@Component({
  selector: 'xspot-app-form-control-errors',
  standalone: true,
  imports: [CommonModule, TranslocoPipe, SafeHtmlPipe],
  templateUrl: './form-control-errors.component.html',
  styleUrl: './form-control-errors.component.scss',
  providers: [
    provideTranslocoScope({
      scope: 'errors',
      loader: scopeLoaderFactory(
        (lang: string) => import(`../../i18n/${lang}.json`)
      ),
    }),
  ],
})
export class FormControlErrorsComponent implements OnInit {
  @Input({ required: true }) public control!: AbstractControl | null;
  @Input() public prefix: string = '';

  public get errors(): { [key: string]: string } {
    return this.control?.errors || {};
  }

  constructor(private cdr: ChangeDetectorRef) {}

  public hasHtmlTags(text: string): boolean {
    return /<\/?[a-z][\s\S]*>/i.test(text);
  }

  public ngOnInit(): void {
    if (this.control) {
      this.control.statusChanges.pipe(untilDestroyed(this)).subscribe(() => {
        this.cdr.markForCheck();
      });
    }
  }
}
