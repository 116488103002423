import { Nullable } from './types';

export class YoutubeUtil {
  public static getVideoId(url: string): Nullable<string> {
    const regExp: RegExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match: Nullable<RegExpMatchArray> = url.match(regExp);

    if (match && match[2].length === 11) {
      // returns video ID
      return match[2];
    } else {
      return null;
    }
  }
}
