<ng-container *transloco="let t">
  <div class="flex gap-1 flex-column">
    <div class="flex flex-column w-full image-upload">
      <div
        dragAndDrop
        (fileDropped)="uploadFiles($event)"
        (dragOver)="fileOver = true"
        (dragLeave)="fileOver = false"
        class="flex flex-column justify-content-center align-items-center flex-grow-1 overflow-hidden">
        <img
          *ngIf="coverFormGroup.controls.uri.value; else imagePlaceholder"
          [src]="coverFormGroup.controls.uri.value"
          class="max-h-full max-w-full" />
        <ng-template #imagePlaceholder>
          <div
            class="w-fit flex flex-column align-items-center text-xs text-gray-600 gap-1">
            <ng-container *ngIf="!fileOver; else dropFile">
              <span> {{ t('ui.recommendedAspectRatio') }}</span>
              <span>{{ RecommendedAspectRatio }}</span>
            </ng-container>
            <ng-template #dropFile>
              <i class="pi pi-upload"></i>
              <span>
                {{ t('ui.dropFile') }}
              </span>
            </ng-template>
          </div>
        </ng-template>
      </div>
      <input
        #inputFile
        type="file"
        (change)="upload($event)"
        class="hidden"
        accept=".jpg,.jpeg,.png" />
      <p-button
        [loading]="isLoading$ | async"
        type="button"
        styleClass="w-full justify-content-center"
        (click)="inputFile.click()">
        <i class="pi pi-upload"></i>
        {{ t('ui.uploadFromDisk') }}
      </p-button>
    </div>
    <small class="text-error" *ngIf="errorMessage$ | async as errorMessage">
      {{ t('ui.uploadFailed', { MaxFileSize: MaxFileSize_MB }) }}
    </small>
  </div>
  <xspot-app-form-control-errors
    [control]="coverFormGroup.controls.uri"
    [prefix]="'form'"></xspot-app-form-control-errors>
</ng-container>
