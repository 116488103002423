import { Nullable } from '@xspot-app/common';

export interface GetCalendarDaySlotsDto {
  offerId: string;
  offerVariantId: string;
  locationId: string;
  date: string;
  basketItemId: Nullable<string>;
  offerVariantProducts: GetCalendarDaySlotsOfferVariantProductDto[];
}

export interface GetCalendarDaySlotsOfferVariantProductDto {
  id: string;
  quantity: number;
}
