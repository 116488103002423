import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  NonNullableFormBuilder,
} from '@angular/forms';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { provideTranslocoScope, TranslocoDirective } from '@ngneat/transloco';
import {
  ErrorMessage,
  getErrorMessage,
  MaxFileSize_MB,
  Nullable,
  scopeLoaderFactory,
  StringUtil,
} from '@xspot-app/common';
import { BehaviorSubject, tap } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FileUploadErrorCodes, FileUploadErrorMessages } from '../../consts';
import { LibraryFilesDto } from '../../dtos';
import { FilesLibraryUploadService } from '../../services';
import { LegalFileControlGroup } from '../../models';

@UntilDestroy()
@Component({
  selector: 'xspot-app-legal-files',
  standalone: true,
  imports: [CommonModule, TableModule, ButtonModule, TranslocoDirective],
  templateUrl: './legal-files.component.html',
  providers: [
    provideTranslocoScope({
      scope: 'ui',
      loader: scopeLoaderFactory(
        (lang: string) => import(`../../i18n/${lang}.json`)
      ),
    }),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LegalFilesComponent implements OnInit {
  @Input({ required: true })
  public formArray!: FormArray<FormGroup<LegalFileControlGroup>>;
  @Input({ required: true })
  public maxFilesCount!: number;
  @Input()
  public previewMode: boolean = false;

  protected MaxFileSize_MB = MaxFileSize_MB;
  protected errorMessage$ = new BehaviorSubject<Nullable<ErrorMessage>>(null);
  protected isLoading$ = new BehaviorSubject<boolean>(false);

  constructor(
    private filesLibraryUploadService: FilesLibraryUploadService,
    private fb: FormBuilder,
    private nfb: NonNullableFormBuilder,
    private cdr: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this.formArray.valueChanges
      .pipe(
        untilDestroyed(this),
        tap(() => {
          this.cdr.detectChanges();
        })
      )
      .subscribe();
  }

  protected onRowReorderHandler(): void {
    this.formArray.controls.forEach((group, index) => {
      group.patchValue({ ordinal: index });
    });
  }

  protected downloadFile(uri: string): void {
    window.open(uri, '_blank');
  }

  protected removeFile(index: number): void {
    this.formArray.removeAt(index);
    this.onRowReorderHandler();
  }

  protected uploadLegalFile(event: Event): void {
    this.errorMessage$.next(null);
    const fileInput: HTMLInputElement = event.target as HTMLInputElement;

    if (this.formArray.length >= this.maxFilesCount) {
      this.errorMessage$.next(
        getErrorMessage(
          FileUploadErrorCodes.TooManyFiles,
          FileUploadErrorMessages,
          () => 'error'
        )
      );
      fileInput.value = StringUtil.empty;
      return;
    }

    if (!fileInput.files || !fileInput.files[0]) return;
    const file: File = fileInput.files[0];
    fileInput.value = StringUtil.empty;

    this.isLoading$.next(true);
    this.filesLibraryUploadService
      .upload([file])
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (result: LibraryFilesDto): void => {
          this.isLoading$.next(false);
          const legalFile = this.fb.group<LegalFileControlGroup>({
            id: this.fb.control(null),
            fileId: this.fb.control(result[0].id),
            ordinal: this.nfb.control(this.formArray.length),
            name: this.nfb.control(result[0].originalFileName),
            uri: this.nfb.control(result[0].uri),
          });
          this.formArray.push(legalFile);
        },
        error: ({ error }): void => {
          this.isLoading$.next(false);
          this.errorMessage$.next(
            getErrorMessage(
              error.errorCode,
              FileUploadErrorMessages,
              () => 'error'
            )
          );
        },
      });
  }
}
