import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  LanguageControlGroup,
  PaymentStatus,
  UiContext,
} from '@xspot-app/common';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ButtonModule } from 'primeng/button';
import { TranslocoDirective, TranslocoService } from '@ngneat/transloco';
import { DividerModule } from 'primeng/divider';
import { UserOrderDetailsModel } from '../../domain';
import { CurrencyFormatPipe } from '@xspot-app/shared/ui';

@Component({
  selector: 'xspot-app-order-details-modal',
  standalone: true,
  imports: [
    ButtonModule,
    CommonModule,
    CurrencyFormatPipe,
    DividerModule,
    TranslocoDirective,
  ],
  templateUrl: './order-details-modal.component.html',
  styleUrl: './order-details-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderDetailsModalComponent {
  protected UiContext = UiContext;

  protected PaymentStatus = PaymentStatus;

  protected lang$ = this.translocoService.langChanges$;

  constructor(
    protected dialogConfig: DynamicDialogConfig<{
      orderDetails: UserOrderDetailsModel;
    }>,
    private dialogRef: DynamicDialogRef,
    private translocoService: TranslocoService
  ) {}

  protected close(): void {
    this.dialogRef.close();
  }

  protected parseLocationNames(locationNames: LanguageControlGroup[]): string {
    return locationNames
      .map(location => location[this.translocoService.getActiveLang()])
      .join(', ');
  }

  protected parseDate(date: string): string {
    return new Date(date).toLocaleDateString(
      this.translocoService.getActiveLang()
    );
  }
}
