<ng-container *transloco="let t">
  <ng-container *ngIf="selectedLanguage$ | async as lang">
    <ng-container *ngIf="selectedVariant$ | async as selectedVariant">
      <ng-container *ngIf="locations$ | async as locations">
        @if (isCreditOffer$ | async) {
          <div class="flex flex-column gap-1">
            <span class="text-sm">
              {{
                t(
                  'offers.details.selectOfferDetailsPanel.availableForLocations'
                )
              }}
            </span>
            <span class="text-700">
              {{ offerLocationNames$ | async }}
            </span>
          </div>
        } @else {
          <p-dropdown
            [formControl]="selectedLocation"
            [style]="{ minWidth: '100%' }"
            [options]="locations"
            (onChange)="onChangeLocation($event)"
            optionLabel="name"
            [placeholder]="
              t('offers.details.selectOfferDetailsPanel.selectCity')
            ">
            <ng-template pTemplate="selectedItem" let-item>
              {{ item.name[lang] }}
            </ng-template>
            <ng-template pTemplate="item" let-item>
              {{ item.name[lang] }}
            </ng-template>
          </p-dropdown>
        }
      </ng-container>

      <ng-container *ngIf="variants$ | async as variants">
        <ng-container *ngIf="variants.length > 1">
          <p class="font-bold mt-4 text-sm">
            {{ t('offers.details.selectOfferDetailsPanel.variants') }}
          </p>
          <div class="flex flex-column gap-3">
            @for (variant of variants; track variant.id) {
              <div
                class="relative border-round-xl"
                (click)="changeVariant(variant.id)"
                [isItemSelected]="selectedVariant?.id === variant.id">
                <div
                  [ngClass]="{
                    'border-gray-400': selectedVariant?.id !== variant.id,
                    'border-primary': selectedVariant?.id === variant.id,
                  }"
                  class="px-4 py-3 font-bold cursor-pointer border-2 border-round-lg flex align-items-center gap-2">
                  <span>{{ variant.name[lang] }}</span>
                  <i
                    class="pi pi-info-circle text-xl"
                    [xTooltip]="variant.information[lang]"></i>
                </div>
              </div>
            }
          </div>
        </ng-container>
      </ng-container>
      <p class="font-bold mt-4 text-sm">
        {{ t('offers.details.selectOfferDetailsPanel.purchaseOptions') }}
      </p>
      <xspot-app-volume-prices></xspot-app-volume-prices>
      @for (product of selectedVariant.products; track product.id) {
        <xspot-app-product-selector
          [product]="product"></xspot-app-product-selector>
      }
      <xspot-app-offer-promotion
        buttonClass="w-full"
        styleClass="border-bottom-1 py-3 w-full bg-white surface-border" />
      <ng-container
        *ngIf="
          selectedOfferVariantProducts$ | async as selectedOfferVariantProducts
        ">
        <div class="mt-4 flex flex-column gap-2">
          @for (product of selectedOfferVariantProducts; track product.id) {
            <span>{{ product.displayName[lang] }} x{{ product.quantity }}</span>
          }
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
